export default {
  createScheduling: '/scheduling',
  findScheduling: (userEmail: string) =>
    `/scheduling?userAuthEmail=${userEmail}`,
  findSchedulingAvailableTime: '/scheduling/available-time',
  findServices: '/services',
  cancelScheduling: (id: string) => `/scheduling/${id}/cancel`,
  findPOrofessionals: '/professionals',
  createRating: '/ratings',
  updateUser: (id: string | number) => `/users/${id}`,
};
