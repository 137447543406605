import { Box } from '@mui/material';
import LinearIndeterminate from '../../../../shared/components/linear-indeterminate/LinearIndeterminate';
import { getCompanyId } from '../../../../shared/config';
import useRequest from '../../../../shared/hooks/useRequest';
import { Service } from '../../../../api/service';
import { useNovoAgendamentoContext } from '../../contexts/NewScheduleContext';
import { ViewCardService } from './view-card-service/ViewCardService';
import SelectionItemsLayout from '../../SelectionItemsLayout';
import { formatCurrency } from '../../../../shared/utils/currency-helpers';
import { formatHHMMToMinutesInLiteral } from '../../../../shared/utils/time-utils';
import apiUrls from '../../../../shared/utils/api-urls';
import { useAnalytics } from '../../../../shared/hooks/useAnalytics';
import { useEffect } from 'react';
import {
  EventAnalytics,
  PageNameAnalytics,
} from '../../../../shared/utils/constants';

interface ItemServicoProps {
  name?: string;
  price?: string;
  promotionPrice?: string;
  time?: string;
  hasPromotion: boolean;
  showSkeleton?: boolean;
  onClick: () => void;
}

function ItemServico(props: ItemServicoProps) {
  return (
    <SelectionItemsLayout>
      <Box width={'100%'} display={'flex'} onClick={() => props.onClick()}>
        <ViewCardService
          name={props?.name}
          price={props?.price}
          time={props?.time}
          hasPromotion={props?.hasPromotion}
          promotionPrice={props.promotionPrice}
        />
      </Box>
    </SelectionItemsLayout>
  );
}

export const SelectServiceNewSchedule = () => {
  const { selecionarServico, setMostraModalServico } =
    useNovoAgendamentoContext();
  const { logPageView, logError } = useAnalytics();

  useEffect(() => {
    logPageView(PageNameAnalytics.pageSelectService);
  }, []);

  const {
    data: services,
    isRequesting,
    error,
  } = useRequest<Service[]>({
    url: `${apiUrls.findServices}?companyId=${getCompanyId()}`,
    method: 'GET',
  });

  useEffect(() => {
    if (error) {
      logError(EventAnalytics.errorLoadServices, error?.error ?? '');
    }
  }, [error]);

  const ServiceList = () => {
    return (
      <>
        {services?.map((item, index) => (
          <ItemServico
            key={index}
            name={item.name}
            price={formatCurrency(item.price)}
            promotionPrice={formatCurrency(item.promotionPrice)}
            time={formatHHMMToMinutesInLiteral(item.durationTime)}
            hasPromotion={item.hasPromotion}
            onClick={() => {
              selecionarServico({
                id: item.id,
                nome: item.name,
                tempo: formatHHMMToMinutesInLiteral(item.durationTime),
                valor: formatCurrency(item.price),
                hasPromotion: item.hasPromotion,
              });
              setMostraModalServico(false);
            }}
          />
        ))}
      </>
    );
  };

  return (
    <>
      {error && setMostraModalServico(false)}
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        {isRequesting && <LinearIndeterminate />}
        {!isRequesting &&
          (services && services.length > 0 ? (
            <ServiceList />
          ) : (
            <>Não foi encontrado nenhum serviço.Tente novamente mais tarde</>
          ))}
      </Box>
    </>
  );
};
