import { Box, Paper, Typography, useTheme } from '@mui/material';

type AppointmentListItemTableRowsProps = {
  data: string;
  horario: string;
  empresa: string;
  profissional: string;
  servico: string;
  valor: string;
  status: string;
  aoClicar: () => void;
};

export default function AppointmentListItemTableRows(
  props: AppointmentListItemTableRowsProps
) {
  const theme = useTheme();

  return (
    <Box
      style={{ cursor: 'pointer' }}
      component={Paper}
      display={'flex'}
      alignContent={'space-between'}
      paddingTop={theme.spacing(4)}
      paddingBottom={theme.spacing(4)}
      paddingLeft={theme.spacing(2)}
      paddingRight={theme.spacing(2)}
      gap={1}
      onClick={props.aoClicar}
    >
      <Box display={'flex'} width={'100%'} gap={2}>
        <Typography component={Box} width={theme.spacing(12)}>
          {props.data}
        </Typography>
        <Typography component={Box} width={theme.spacing(16)}>
          {props.horario}
        </Typography>
        <Typography component={Box} width={theme.spacing(20)}>
          {props.empresa}
        </Typography>
        <Typography component={Box} width={theme.spacing(14)}>
          {props.profissional}
        </Typography>
        <Typography component={Box} width={theme.spacing(30)}>
          {props.servico}
        </Typography>
        <Typography component={Box} width={theme.spacing(10)}>
          {props.valor}
        </Typography>
        <Typography component={Box} width={theme.spacing(10)}>
          {props.status}
        </Typography>
      </Box>
    </Box>
  );
}
