import { SchedulingState } from './constants';
import messagesHelpers from './messages-helpers';

export const getDescriptionStatusScheduleByState = (value: SchedulingState) => {
  if (value === SchedulingState.CREATED) {
    return 'Novo';
  }
  if (value === SchedulingState.COMPLETE) {
    return 'Concluido';
  }
  if (value === SchedulingState.CANCELLED) {
    return 'Cancelado';
  }
  if (value === SchedulingState.PENDING) {
    return 'Pendente';
  }
  return '';
};

export const getMessageErrorFirebase = (code: string) => {
  const firebaseAuthenticationErrors: any = {
    'network-request-failed': messagesHelpers.NETWORK_REQUEST_FAILED,
    'auth/internal-error': messagesHelpers.AUTH_INTERNAL_ERROR,
    'auth/user-not-found': messagesHelpers.AUTH_USER_NOT_FOUND,
    'auth/email-already-in-use': messagesHelpers.AUTH_EMAIL_ALREADY_IN_USE,
    'auth/wrong-password': messagesHelpers.AUTH_WRONG_PASSWORD,
  };
  const errorMessage: string = firebaseAuthenticationErrors[code];
  return errorMessage || code;
};
