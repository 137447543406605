import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

interface IButtonSignInGoogle {
  loading: boolean;
  onClick: () => void;
}
export const ButtonSignInGoogle: React.FC<IButtonSignInGoogle> = (params) => {
  const { onClick, loading } = params;
  return (
    <>
      {loading ? (
        <Button
          size="large"
          variant="contained"
          fullWidth
          style={{ backgroundColor: '#ea4335' }}
          startIcon={<GoogleIcon />}
        >
          Entrando....
        </Button>
      ) : (
        <Button
          size="large"
          variant="contained"
          fullWidth
          style={{ backgroundColor: '#ea4335' }}
          startIcon={<GoogleIcon />}
          onClick={onClick}
        >
          Entrar com Google
        </Button>
      )}
    </>
  );
};
