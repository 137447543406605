import { z } from 'zod';

export const AppConfigSchema = z.object({
  api: z.object({ base_url: z.string() }),
  keyTokenLocalStorage: z.string(),
  firebaseConfigApiKey: z.string(),
  firebaseConfigAuthDomain: z.string(),
  firebaseConfigProjectId: z.string(),
  firebaseConfigStorageBucket: z.string(),
  firebaseConfigMessagingSenderId: z.string(),
  firebaseConfigAppId: z.string(),
  firebaseConfigMeasurementId: z.string(),
  appAccessControlCompaniesLocalKey: z.string(),
});

export type AppConfig = z.infer<typeof AppConfigSchema>;

export class AppConfigMapper {
  static toAppConfig(env: NodeJS.ProcessEnv): AppConfig {
    const data = {
      keyTokenLocalStorage: env.REACT_APP_KEY_AUTH_SESSION_STORAGE
        ? String(env.REACT_APP_KEY_AUTH_SESSION_STORAGE)
        : 'key_user_id',
      api: {
        base_url: env.REACT_APP_BASE_URL_API as string,
      },
      firebaseConfigApiKey: env.REACT_APP_FIREBASE_API_KEY as string,
      firebaseConfigAuthDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
      firebaseConfigProjectId: env.REACT_APP_FIREBASE_PROJECT_ID as string,
      firebaseConfigStorageBucket:
        env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
      firebaseConfigMessagingSenderId:
        env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
      firebaseConfigAppId: env.REACT_APP_FIREBASE_APP_ID as string,
      firebaseConfigMeasurementId:
        env.REACT_APP_FIREBASE_MEASUREMENT_ID as string,
      appAccessControlCompaniesLocalKey:
        env.REACT_APP_ACCESS_CONTROL_COMPANIES_LOCAL_KEY as string,
    };

    return AppConfigSchema.parse(data);
  }
}
