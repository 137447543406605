import { Avatar, Box, Icon, Typography, useTheme } from '@mui/material';

export const UserInfoScheduleDetails = (props: {
  username: string;
  avatar: string;
  time: string;
  date: string;
}) => {
  const theme = useTheme();

  return (
    <Box display={'flex'} gap={3} alignItems={'center'}>
      <Avatar
        sx={{ height: theme.spacing(10), width: theme.spacing(10) }}
        src={props.avatar}
      />
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography variant={'h5'} fontWeight={'bold'}>
          {props.username}
        </Typography>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={1}
          >
            <Icon fontSize={'small'}>event</Icon>
            <Typography>{props.date}</Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={1}
          >
            <Icon fontSize={'small'}>access_time</Icon>
            <Typography>{props.time}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
