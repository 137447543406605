import { Container, Alert, AlertTitle } from '@mui/material';

const CompanyDeactivatedCmoponent = ({
  fantasyName,
}: {
  fantasyName: string;
}) => {
  const alertStyle = {
    backgroundColor: '#ff5252',
    color: 'white',
    fontSize: '24px',
    padding: '16px',
    borderRadius: '8px',
  };

  return (
    <Container maxWidth="md">
      <Alert style={alertStyle}>
        <AlertTitle>Empresa Desativada</AlertTitle>A empresa{' '}
        <strong>{fantasyName}</strong> está desativada. Entre em contato com o
        suporte para obter mais informações.
      </Alert>
    </Container>
  );
};

export default CompanyDeactivatedCmoponent;
