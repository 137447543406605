import { ReactNode, createContext, useContext } from 'react';
import { LocalStorage } from '../../utils/storage-helpers';
import { appConfig, getCompanyId } from '../../config';
import { RouteConfig } from '../../config/route';
import { useNavigate } from 'react-router-dom';

export const ApplicationContext = createContext(
  {} as {
    isSettings: () => boolean;
    getToken: () => string | null;
    updateToken: (token: string) => void;
    redirectToLogin: () => void;
  }
);
export const useApplicationContext = () => useContext(ApplicationContext);
export const ApplicationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const updateToken = (token: string): void => {
    LocalStorage.setItem(appConfig.keyTokenLocalStorage, token);
  };

  const getToken = (): string | null => {
    return LocalStorage.getValue(appConfig.keyTokenLocalStorage);
  };

  const isSettings = (): boolean => {
    const companyId = LocalStorage.getValue(getCompanyId());
    return !!companyId;
  };

  const redirectToLogin = (): void => {
    navigate(RouteConfig.signIn, { replace: true });
  };

  return (
    <ApplicationContext.Provider
      value={{
        isSettings,
        getToken,
        updateToken,
        redirectToLogin,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
