import { ReactNode, createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplicationContext } from '../application';
import { RouteConfig } from '../../config/route';

export const AuthContext = createContext({});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isUserAuthorized, setIsUserAuthorized] = useState(false);
  const navigate = useNavigate();
  const { getToken } = useApplicationContext();

  const isAuthorized = () => getToken() !== null;

  const signOut = () => {
    navigate(RouteConfig.signIn, { replace: true });
  };

  useEffect(() => {
    if (!isAuthorized()) {
      setIsUserAuthorized(false);
      signOut();
    } else {
      setIsUserAuthorized(true);
    }
  }, []);

  return <>{isUserAuthorized && children}</>;
};
