/* eslint-disable no-unused-vars */
import { createContext, useContext } from 'react';

export type ProfissionalSelecionadoData = {
  id: string;
  nome: string;
  avatar: string;
};
export type ServicoSelecionadoData = {
  id: string;
  nome: string;
  tempo: string;
  valor: string;
  hasPromotion: boolean;
};

export type DataEHoraSelecionadoData = {
  diaDaSemana: string;
  data: string;
  tempo: string;
};

interface NovoAgendamentoData {
  mostraModalProfissional: boolean;
  mostraModalServico: boolean;
  mostraModalDataEHora: boolean;
  profissionalSelecionado?: ProfissionalSelecionadoData | undefined;
  servicoSelecionado?: ServicoSelecionadoData | undefined;
  dataEHoraSelecionada?: DataEHoraSelecionadoData | undefined;
  scheduleCreated?: boolean;

  setMostraModalProfissional: (val: boolean) => void;
  setMostraModalServico: (val: boolean) => void;
  setMostraModalDataEHora: (val: boolean) => void;
  selecionarProfissional: (
    profissional: ProfissionalSelecionadoData | undefined
  ) => void;
  selecionarServico: (service: ServicoSelecionadoData | undefined) => void;
  selecionarDataEHora: (data: DataEHoraSelecionadoData | undefined) => void;
  setScheduleCreated: (val: boolean) => void;
}

export const NovoAgendamentoContext = createContext({} as NovoAgendamentoData);

export const useNovoAgendamentoContext = () => {
  return useContext(NovoAgendamentoContext);
};
