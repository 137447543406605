import {
  Box,
  Dialog,
  Icon,
  IconButton,
  Slide,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactNode } from 'react';

interface ModalLadoDireitoProps {
  tituloDaPagina: string;
  children: ReactNode;
  aberto?: boolean;
  aoClicarEmFechar: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const ModalLadoDireito: React.FC<ModalLadoDireitoProps> = ({
  tituloDaPagina,
  aberto = false,
  aoClicarEmFechar,
  children,
}) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  return (
    <Dialog
      open={aberto}
      onClose={aoClicarEmFechar}
      TransitionComponent={Transition}
      fullScreen={mdDown}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{
        sx: {
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          margin: 0,
          height: '100%',
          maxHeight: '100%',
        },
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography paddingLeft={1} paddingTop={1} variant="h6">
          {tituloDaPagina}
        </Typography>
        <IconButton size="large" onClick={aoClicarEmFechar}>
          <Icon>close</Icon>
        </IconButton>
      </Box>
      <Box padding={theme.spacing(2)}>{children}</Box>
    </Dialog>
  );
};
