import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import apiUrls from '../../shared/utils/api-urls';
import { api } from '../../infra/api/http-client';
import { PostSchedulePayload } from '../../api/schdeule';
import {
  AlertConfig,
  useAlertContext,
} from '../../shared/contexts/AlertContext';
import DateTimeNewSchedule from './date-time-new-schedule/DateTimeNewSchedule';
import ProfessionalNewSchedule from './professional-new-schedule/ProfessionalNewSchedule';
import ServiceNewSchedule from './service-new-schedule/ServiceNewSchedule';
import { useNovoAgendamentoContext } from './contexts/NewScheduleContext';
import serviceError from '../../shared/utils/api-error-codes';
import alertMessage from '../../shared/utils/messages-helpers';
import { getCompanyId } from '../../shared/config';
import { useAnalytics } from '../../shared/hooks/useAnalytics';
import {
  EventAnalytics,
  PageNameAnalytics,
} from '../../shared/utils/constants';
import CenteredCircularProgress from '../../shared/components/centered-circular-progress/CenteredCircularProgress';
import { UserContext } from '../../shared/contexts/UserAuthContext';
import { LocalStorage } from '../../shared/utils/storage-helpers';

export function NewSchedule() {
  const {
    profissionalSelecionado,
    servicoSelecionado,
    dataEHoraSelecionada,
    selecionarProfissional,
    selecionarServico,
    selecionarDataEHora,
  } = useNovoAgendamentoContext();
  const { setConfig: alert } = useAlertContext();
  const { setScheduleCreated } = useNovoAgendamentoContext();
  const [loading, setLoading] = useState(false);
  const { logPageView, logAppoimentCreated, logError } = useAnalytics();

  useEffect(() => {
    logPageView(PageNameAnalytics.pageNewSchedule);
    setScheduleCreated(false);
  }, []);

  const handleCreateScheduleError = (error: string) => {
    const alertConfig: AlertConfig = {
      open: true,
      severity: 'error',
      message: alertMessage.UNKNOWN_ERROR,
    };
    if (error === serviceError.schedulingConflictAtTheSameTime) {
      alertConfig.message = alertMessage.CONFLICT_DATE_AND_TIME;
    }
    if (error === serviceError.userNotFound) {
      alertConfig.message = alertMessage.USER_NOT_FOUND;
    }
    if (error === serviceError.professionalNotFound) {
      alertConfig.message = alertMessage.PROFESSIONAL_NOT_FOUND;
    }
    if (error === serviceError.companyNotFound) {
      alertConfig.message = alertMessage.COMPANY_NOT_FOUND;
    }
    if (
      error === serviceError.serviceNotFound ||
      error === serviceError.serviceIsNotPartCompany
    ) {
      alertConfig.message = alertMessage.SERVICE_NOT_FOUND;
    }
    if (error === serviceError.eventDateBePastDate) {
      alertConfig.message = alertMessage.EVENT_DATE_BE_PAST_DATE;
    }
    if (error === serviceError.schedulingWithEqualUserAndProfessional) {
      alertConfig.message = alertMessage.USER_CANNOT_BE_PROFESSIONAL;
    }
    alert(alertConfig);
  };

  const removeJourneyCache = () => {
    LocalStorage.deleteItem(getCompanyId());
  };

  const handleCreateScheduleSuccess = () => {
    alert({
      open: true,
      severity: 'success',
      message: alertMessage.CREATED_SCHEDULE_SUCCESS,
    });
    selecionarProfissional(undefined);
    selecionarServico(undefined);
    selecionarDataEHora(undefined);
    setScheduleCreated(true);

    removeJourneyCache();
  };

  const handleConfirmClick = async () => {
    if (
      !dataEHoraSelecionada ||
      !profissionalSelecionado ||
      !servicoSelecionado
    ) {
      alert({
        open: true,
        severity: 'error',
        message: alertMessage.CREATE_SCHEDULE_INVALID_FORM,
      });
      return;
    }

    setLoading(true);
    api<PostSchedulePayload>({
      url: apiUrls.createScheduling,
      method: 'POST',
      data: {
        eventDate: dataEHoraSelecionada?.data,
        eventTime: dataEHoraSelecionada?.tempo,
        companyId: getCompanyId(),
        professionalId: profissionalSelecionado?.id,
        serviceId: servicoSelecionado?.id,
        userAuthId: UserContext.getEmail(),
      } as PostSchedulePayload,
    })
      .then(({ errorData, requestSuccess }) => {
        if (requestSuccess) {
          handleCreateScheduleSuccess();
          logAppoimentCreated();
        } else {
          const errorMessage = errorData?.error || '';
          handleCreateScheduleError(errorMessage);
          logError(EventAnalytics.errorAppoimentCreated, errorMessage);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <CenteredCircularProgress show={loading} />

      <Box display={'flex'} flexDirection={'column'} gap={4}>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <ServiceNewSchedule />
          <ProfessionalNewSchedule />
          <DateTimeNewSchedule />
        </Box>
        <Button
          disabled={
            profissionalSelecionado == undefined ||
            servicoSelecionado == undefined ||
            dataEHoraSelecionada == undefined
          }
          variant={'contained'}
          size={'large'}
          onClick={handleConfirmClick}
        >
          Confirmar
        </Button>
      </Box>
    </>
  );
}
