import {
  Box,
  Button,
  Icon,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAlertContext } from '../../../shared/contexts/AlertContext';
import { putUser } from '../../../api/user';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAnalytics } from '../../../shared/hooks/useAnalytics';
import {
  EventAnalytics,
  PageNameAnalytics,
} from '../../../shared/utils/constants';

type UpdateProfile = { name: string; phoneNumber: string };
const schema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório'),
  phoneNumber: yup.string(),
  // .min(6, 'O número de telefone deve ter no mínimo 6 caracteres')
  // .max(14, 'O número de telefone deve ter no máximo 14 caracteres'),
});

export const ProfileEditingForm = (props: {
  id?: string;
  name: string;
  phone: string;
  email: string;
  onChange: () => void;
}) => {
  const { setConfig: alert } = useAlertContext();
  const [isSavingProfile, savingProfile] = useState(false);
  const { logPageView, logUserProfileUpdated, logError } = useAnalytics();

  useEffect(() => {
    logPageView(PageNameAnalytics.pageEditProfile);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateProfile>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: props.name,
      phoneNumber: props.phone ? props.phone.substring(3) : props.phone,
    },
  });

  const handleSave: SubmitHandler<UpdateProfile> = async (data) => {
    if (props.id) {
      savingProfile(true);
      if (data.phoneNumber) {
        data.phoneNumber = '+55' + data.phoneNumber;
      }
      const { ok } = await putUser(props.email, {
        name: data.name,
        phoneNumber: data.phoneNumber,
      });
      savingProfile(false);
      if (ok) {
        alert({
          open: true,
          severity: 'success',
          message: 'Perfil atualizado com êxito.',
        });
        props.onChange();
        logUserProfileUpdated();
      } else {
        logError(EventAnalytics.errorUserProfileUpdated, '');
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth="sm"
        margin="auto"
        width={'100%'}
        gap={3}
      >
        <TextField
          {...register('name')}
          error={errors.name?.message !== undefined}
          helperText={errors.name?.message}
          label="Nome"
          type="text"
          fullWidth
          inputProps={{
            maxLength: 50,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>person</Icon>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          {...register('phoneNumber')}
          error={errors.phoneNumber?.message !== undefined}
          helperText={errors.phoneNumber?.message}
          label="Telefone"
          type="tel"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>phone</Icon>
              </InputAdornment>
            ),
          }}
        />

        {isSavingProfile && (
          <Skeleton
            component={Button}
            size={'large'}
            variant={'rectangular'}
            width={'100%'}
          >
            Salvando...
          </Skeleton>
        )}

        {!isSavingProfile && (
          <Button
            variant={'contained'}
            size={'large'}
            onClick={handleSubmit(handleSave)}
          >
            Salvar
          </Button>
        )}
      </Box>
    </>
  );
};
