import { createTheme } from '@mui/material';

export const LightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FF4081', // Rosa brilhante
      dark: '#F50057', // Rosa mais escuro
      light: '#FF80AB', // Rosa mais claro
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#6200EA', // Roxo profundo
      dark: '#3700B3', // Roxo mais escuro
      light: '#9575CD', // Roxo mais claro
      contrastText: '#ffffff',
    },
    background: {
      paper: '#ffffff', // Branco
      default: '#F5F5F5', // Cinza claro
    },
  },
});
