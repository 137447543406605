import { useLocation } from 'react-router-dom';
import { useAppThemeContext } from '../../contexts';
import { useEffect, useState } from 'react';
import { appConfig } from '../../config';
import {
  CompanySegment,
  CompanyState,
  CompnayResponse,
  getCompanyById,
} from '../../../api/company.service';
import CenteredCircularProgress from '../centered-circular-progress/CenteredCircularProgress';
import { StatePage } from '../../config/state-page';
import ErrorPage from '../error-page';
import { localStorage } from '../../../infra/local-storage';
import CompanyDeactivatedCmoponent from './CompanyDeactivatedComponent';

export const AccessControlCompaniesComponent = ({
  children,
}: {
  children: any;
}) => {
  const location = useLocation();
  const [statePage, setStatePage] = useState<StatePage>('error');
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [companyState, setCompanyState] = useState<CompanyState | undefined>();
  const [fantasyName, setFantasyName] = useState<string | undefined>();

  const theme = useAppThemeContext();

  const isDarkTheme = (segment: CompanySegment): boolean => {
    return segment === 'BARBERS' || segment === 'UNISEX_SALON';
  };
  const isSalonTheme = (segment: CompanySegment): boolean => {
    return segment === 'MANICURE_AND_PEDICURE';
  };
  const isLightTheme = (segment: CompanySegment): boolean => {
    return segment === 'SPA';
  };

  const initializeVariables = () => {
    const companyId = getCompanyId();
    if (!companyId) {
      return { initializedVariables: false };
    }
    setCompanyId(companyId);
    return { initializedVariables: true };
  };

  const isCompanyDeactivated = (): boolean => {
    return companyState !== 'activated';
  };

  const validate = async () => {
    const { initializedVariables } = initializeVariables();
    if (!initializedVariables) {
      setStatePage('error');
      return;
    }

    if (!companyId) {
      setStatePage('error');
      return;
    }

    const resultCompany = await getCompny({ companyId });
    if (!resultCompany) {
      return;
    }
    setCompanyState(resultCompany.state);
    setFantasyName(resultCompany.fantasyName);

    if (isDarkTheme(resultCompany.segment)) {
      theme.toggleTheme('dark');
    }
    if (isSalonTheme(resultCompany.segment)) {
      theme.toggleTheme('salon');
    }
    if (isLightTheme(resultCompany.segment)) {
      theme.toggleTheme('light');
    }

    localStorage.setSegment(resultCompany.segment);
  };

  const getCompanyId = (): string | undefined => {
    const queryParams = new URLSearchParams(location.search);
    const localKey = appConfig.appAccessControlCompaniesLocalKey;
    const companyIdQueryParams = queryParams.get(localKey);
    if (!companyIdQueryParams) {
      const companyIdLocalStorage = localStorage.getCompanyId();
      if (!companyIdLocalStorage) {
        return;
      }
      return companyIdLocalStorage;
    }
    localStorage.setCompanyId(companyIdQueryParams);
    return companyIdQueryParams;
  };

  async function getCompny({
    companyId,
  }: {
    companyId: string;
  }): Promise<CompnayResponse | null> {
    try {
      setStatePage('loading');
      const result = await getCompanyById(companyId);
      setStatePage('success');
      return result;
    } catch (err) {
      setStatePage('error');
      return null;
    }
  }

  const refreshPage = () => {
    validate();
  };

  const handleReloadPage = () => {
    refreshPage();
  };

  useEffect(() => {
    validate();
  }, [companyId, fantasyName, companyState]);

  if (statePage === 'loading') {
    return <CenteredCircularProgress show={true} />;
  }

  if (statePage === 'error') {
    return <ErrorPage onReloadClick={handleReloadPage} />;
  }

  if (isCompanyDeactivated()) {
    return <CompanyDeactivatedCmoponent fantasyName={fantasyName ?? ''} />;
  }

  return <>{children}</>;
};
