import {
  Box,
  Icon,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { useDrawerContext } from '../contexts';

interface LayoutBaseDePaginaProps {
  title?: string;
  toolbar?: ReactNode;
  children: ReactNode;
  subtitle?: string;
  padding?: number;
}

export const LayoutBaseDePagina = (props: LayoutBaseDePaginaProps) => {
  const { title, toolbar, children, subtitle, padding } = props;
  const theme = useTheme();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { toggleDrawerOpen } = useDrawerContext();

  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        gap={1}
        bgcolor={theme.palette.background.default}
      >
        <Box
          padding={1}
          height={theme.spacing(smDown ? 6 : mdDown ? 8 : 12)}
          display="flex"
          alignItems="center"
          gap={1}
        >
          {smDown && (
            <IconButton onClick={toggleDrawerOpen}>
              <Icon>menu</Icon>
            </IconButton>
          )}

          <Typography
            variant={smDown ? 'h5' : mdDown ? 'h4' : 'h3'}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {title ?? ''}
          </Typography>
        </Box>

        {toolbar && <Box>{toolbar}</Box>}
        <Box flex={1} overflow="auto">
          {subtitle && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              padding={2}
            >
              <Typography>{subtitle}</Typography>
            </Box>
          )}
          <Box padding={padding ?? 2}>{children}</Box>
        </Box>
      </Box>
    </>
  );
};
