import { Box, Typography, Icon, useTheme } from '@mui/material';

interface ServicoProps {
  name?: string;
  price?: string;
  hasPromotion?: boolean;
  promotionPrice?: string;
  time?: string;
}

export function ViewCardService(props: ServicoProps) {
  const theme = useTheme();

  return (
    <>
      <Box display={'flex'} width={'100%'} alignItems={'center'}>
        <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={2}>
          <Typography
            textOverflow={'ellipsis'}
            overflow={'hidden'}
            textAlign={'start'}
          >
            {props.name}
          </Typography>
          <Box display={'flex'} flex={1} gap={2}>
            <Box display={'flex'} gap={5}>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography fontSize={theme.spacing(1.5)}>Valor</Typography>
                <Box display={'flex'} flexDirection={'column'}>
                  {props.hasPromotion ? (
                    <>
                      <Typography
                        style={{
                          textDecoration: 'line-through',
                        }}
                        variant="caption"
                      >
                        {props.price}
                      </Typography>
                      <Typography>{props.promotionPrice}</Typography>
                    </>
                  ) : (
                    <Typography>{props.price}</Typography>
                  )}
                </Box>
              </Box>
              <Box display={'flex'} flexDirection={'column'}>
                <Typography fontSize={theme.spacing(1.5)}>Tempo</Typography>
                <Typography>{props.time}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display={'flex'}>
          <Icon>navigate_next</Icon>
        </Box>
      </Box>
    </>
  );
}
