export enum SchedulingState {
  CREATED = 'created',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
}

export enum PageNameAnalytics {
  pageSchedule = 'Agendamentos',
  pageSignin = 'Login',
  pageProfile = 'Perfil',
  pageNewSchedule = 'Novo Agendamento',
  pageDetailsSchedule = 'Detalhes do Agendamento',
  pagePasswordReset = 'Redefinição de senha',
  pageSignup = 'Inscreva-se',
  pageSelectService = 'Selecionar serviço',
  pageSelectProfessional = 'Selecionar profissional',
  pageSelectDateTime = 'Selecionar data e hora',
  pageEditProfile = 'Edditar dados do perfil',
}

export const enum EventAnalytics {
  pageView = 'page_view',
  appointmentCreated = 'appointment_created',
  userProfileUpdated = 'user_profile_updated',
  userPasswordResetSent = 'user_password_reset_sent',
  userRegistrationCreated = 'user_registration_created',
  loginSucceeded = 'login_succeeded',
  appointmentCancelled = 'appointment_cancelled',
  errorAppoimentCreated = 'error_appoiment_created',
  errorUserProfileUpdated = 'error_user_profile_updated',
  errorUserPasswordResetSent = 'error_user_password_reset_sent',
  errorUserRegistrationCreated = 'error_user_registration_created',
  errorSignin = 'error_login',
  errorAppointmentCancelled = 'error_appointment_cancelled',
  errorLoadAppointment = 'error_load_appointment',
  errorLoadServices = 'error_load_services',
  errorLoadProfessionals = 'error_load_professionals',
  errorLoadAvailableTime = 'error_available_time',
}
