import { Box, Paper, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export default function SelectedItemsLayout({
  children,
}: {
  children: ReactNode;
}) {
  const theme = useTheme();

  return (
    <Box
      component={Paper}
      variant="outlined"
      display={'flex'}
      alignItems={'center'}
      gap={1}
      padding={theme.spacing(2)}
      borderRadius={theme.spacing(2)}
      sx={{
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.main}`,
      }}
    >
      {children}
    </Box>
  );
}
