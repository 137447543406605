import { Avatar, Box, Chip, Icon, Skeleton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import SelectionItemsLayout from '../../../SelectionItemsLayout';

type ProfessionalCardProps = {
  id?: string;
  nome?: string;
  avatar?: string;
  avaliacao?: string | null;
  aoClicar: () => void;
};

type TemplateProps = {
  name: ReactNode;
  avatar: ReactNode;
  rating?: ReactNode;
  icon?: ReactNode;
  aoClicar: () => void;
};

function Template(props: TemplateProps) {
  return (
    <>
      <SelectionItemsLayout>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width={'100%'}
          onClick={() => props.aoClicar()}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            width={'100%'}
            gap={2}
          >
            {props.avatar}

            {props.name}

            <Box gap={1} display={'flex'} alignItems={'center'}>
              {props.rating}
            </Box>

            <Box flex={1} alignContent={'end'} textAlign={'end'}>
              {props.icon}
            </Box>
          </Box>
        </Box>
      </SelectionItemsLayout>
    </>
  );
}

export function ProfessionalCardSkeleton() {
  return (
    <>
      <Template
        name={<Skeleton variant="rectangular" width={220} height={25} />}
        avatar={<Skeleton variant="circular" width={40} height={40} />}
        rating={<Skeleton variant="circular" width={50} height={32} />}
        aoClicar={() => {
          return;
        }}
      />
    </>
  );
}

export function ProfessionalCard(props: ProfessionalCardProps) {
  return (
    <>
      <Template
        name={
          <Typography textOverflow={'ellipsis'} overflow={'hidden'} noWrap>
            {props.nome}
          </Typography>
        }
        avatar={<Avatar alt="Remy Sharp" src={props.avatar} />}
        rating={
          props.avaliacao && (
            <Chip
              icon={<Icon fontSize={'small'}>star</Icon>}
              label={props.avaliacao}
            />
          )
        }
        icon={<Icon>navigate_next</Icon>}
        aoClicar={props.aoClicar}
      />
    </>
  );
}
