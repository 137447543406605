import {
  Avatar,
  Box,
  Container,
  Divider,
  Icon,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

interface LayoutBaseAutenticacaoPaginaProps {
  title?: string;
  children: ReactNode;
}
export const LayoutBaseAutenticacaoPagina: React.FC<
  LayoutBaseAutenticacaoPaginaProps
> = ({ title, children }) => {
  const theme = useTheme();

  return (
    <div>
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} justifyContent={'center'}>
          <Avatar sx={{ m: 1, bgcolor: '' }}>
            <Icon>image_icon</Icon>
          </Avatar>
        </Box>
        <Divider />
        <Container maxWidth={'xs'}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={5}
            marginTop={theme.spacing(5)}
          >
            {title && (
              <Typography
                variant={'h4'}
                fontWeight={'bold'}
                textAlign={'center'}
              >
                {title}
              </Typography>
            )}
            {children}
          </Box>
        </Container>
      </Box>
    </div>
  );
};
