import { useBreakpoint } from '../../../shared/hooks/useBreakpoint';
import { AppointmentListItemCard } from './appointment-list-item-card/AppointmentListItemCard';
import AppointmentListItemTableRows from './appointment-list-item-table/AppointmentListItemTableRows';

type AppointmentListItemProps = {
  eventDate: string;
  fantasyName: string;
  startTime: string;
  professionalName: string;
  serviceName: string;
  servicePrice: string;
  status: string;
  onClick: () => void;
};

export default function AppointmentListItem(props: AppointmentListItemProps) {
  const {
    eventDate,
    fantasyName,
    startTime,
    professionalName,
    serviceName,
    servicePrice,
    status,
    onClick,
  } = props;

  const breakpoints = useBreakpoint();

  return (
    <>
      {breakpoints.desktop ? (
        <AppointmentListItemTableRows
          data={eventDate}
          empresa={fantasyName}
          horario={startTime}
          profissional={professionalName}
          servico={serviceName}
          valor={servicePrice}
          status={status}
          aoClicar={onClick}
        />
      ) : (
        <AppointmentListItemCard
          data={eventDate}
          horario={startTime}
          empresa={fantasyName}
          profissional={professionalName}
          servico={serviceName}
          status={status}
          aoClicar={onClick}
        />
      )}
    </>
  );
}
