export const formatCurrency = (value: number): string => {
  if (value === undefined || value === null) {
    return '';
  }
  const formattedValue = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formattedValue;
};
