export const formatDate = (date: string) => {
  const dateSplited = date.split('-');
  return `${dateSplited[2]}/${dateSplited[1]}/${dateSplited[0]}`;
};

export const getDescriptionDayWeek = (day: number) => {
  const days = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];
  return days[day];
};
