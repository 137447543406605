import { createTheme } from '@mui/material';
import { pink, teal } from '@mui/material/colors';

export const SalonTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: pink[500],
      dark: pink[700],
      light: pink[300],
      contrastText: '#ffffff',
    },
    secondary: {
      main: teal[500],
      dark: teal[700],
      light: teal[300],
      contrastText: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: pink[500],
        },
      },
    },
  },
});
