import {
  Box,
  Drawer,
  useTheme,
  Avatar,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Icon,
  useMediaQuery,
  Link,
} from '@mui/material';
import { useEffect } from 'react';
import {
  Outlet,
  useMatch,
  useNavigate,
  useResolvedPath,
} from 'react-router-dom';
import { useDrawerContext } from '../../contexts';
import { UserContext } from '../../contexts/UserAuthContext';
import { useApplicationContext } from '../../contexts/application';
import { LocalStorage } from '../../utils/storage-helpers';
import { appConfig } from '../../config';

interface ListItemProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}

const ListItemLink: React.FC<ListItemProps> = ({
  to,
  icon,
  label,
  onClick,
}) => {
  const navigate = useNavigate();
  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const handleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <ListItemButton selected={!!match} onClick={handleClick}>
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

export const MenuLateral = () => {
  const { redirectToLogin } = useApplicationContext();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { isDrawerOpen, toggleDrawerOpen, drawerOptions } = useDrawerContext();

  function handleSignout() {
    LocalStorage.deleteItem(appConfig.keyTokenLocalStorage);
    redirectToLogin();
  }

  useEffect(() => {
    UserContext.getId();
  }, []);

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        variant={smDown ? 'temporary' : 'permanent'}
        onClose={toggleDrawerOpen}
      >
        <Box
          width={theme.spacing(28)}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            height={theme.spacing(20)}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Avatar
              sx={{ height: theme.spacing(12), width: theme.spacing(12) }}
              src={UserContext.getAvatar()}
            />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link href="#" onClick={handleSignout}>
              Sair
            </Link>
          </Box>

          <br></br>

          <Divider />

          <Box flex={1}>
            <List component="nav">
              {drawerOptions.map((drawerOption) => (
                <ListItemLink
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  to={drawerOption.path}
                  onClick={smDown ? toggleDrawerOpen : undefined}
                />
              ))}
            </List>
          </Box>
        </Box>
      </Drawer>

      <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
        <Outlet />
      </Box>
    </>
  );
};
