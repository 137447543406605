import { Box, Button, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export default function SelectionItemsLayout({
  children,
}: {
  children: ReactNode;
}) {
  const theme = useTheme();

  return (
    <Box
      component={Button}
      padding={theme.spacing(2)}
      borderRadius={theme.spacing(2)}
      variant="outlined"
      color="primary"
      sx={{
        backgroundColor: theme.palette.background.default,
        border: `1px solid ${theme.palette.primary.light}`,
      }}
    >
      {children}
    </Box>
  );
}
