import { BrowserRouter } from 'react-router-dom';
import { AppThemeProvider, DrawerProvider } from './shared/contexts';
import { AppReouters } from './routes';
import { AccessControlCompaniesComponent } from './shared/components/access-control-companies/AccessControlCompaniesComponent';

function App() {
  return (
    <BrowserRouter>
      <AppThemeProvider>
        <AccessControlCompaniesComponent>
          <DrawerProvider>
            <AppReouters />
          </DrawerProvider>
        </AccessControlCompaniesComponent>
      </AppThemeProvider>
    </BrowserRouter>
  );
}

export default App;
