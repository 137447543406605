import { Box, Paper, Typography, useTheme } from '@mui/material';

export const ServiceScheduleDetails = (props: {
  name: string;
  durationTime: string;
  price: string;
  total: string;
  hasPromotion: boolean;
  pricePromotion: string;
}) => {
  const theme = useTheme();

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Typography>Serviço</Typography>
      <Box
        component={Paper}
        display={'flex'}
        flexDirection={'column'}
        padding={theme.spacing(2)}
        gap={2}
      >
        <Typography variant="body1">{props.name}</Typography>
        <Box
          display={'flex'}
          flexDirection={'row'}
          gap={1}
          alignItems={'center'}
        >
          <Typography variant="body2">Duração:</Typography>
          <Typography>{props.durationTime}</Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          gap={1}
          alignItems={'center'}
        >
          <Typography variant="body2">Valor:</Typography>
          {props.hasPromotion && (
            <Typography
              style={{
                textDecoration: 'line-through',
              }}
              variant="caption"
            >
              {props.pricePromotion}
            </Typography>
          )}
          <Typography>{props.price}</Typography>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant={'h6'}>Total</Typography>
        <Typography variant={'h6'}>{props.total}</Typography>
      </Box>
    </Box>
  );
};
