import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModalLadoDireito } from '../../../shared/components';
import { useNovoAgendamentoContext } from '../contexts/NewScheduleContext';
import ActionButton from '../share/ActionsButton';
import { SelecionarProfissionalNovoAgendamento } from './selecionar-profissional-novo-agendamento/SelecionarProfissionalNovoAgendamento';
import CardProfessionalSelected from './card-professional-selected/CardProfessionalSelected';

export default function ProfessionalNewSchedule() {
  const {
    profissionalSelecionado,
    setMostraModalProfissional,
    selecionarProfissional,
    servicoSelecionado,
    mostraModalProfissional,
    selecionarDataEHora,
  } = useNovoAgendamentoContext();

  return (
    <>
      <ModalLadoDireito
        aberto={mostraModalProfissional}
        tituloDaPagina={'Selecionar Profissional'}
        aoClicarEmFechar={() => setMostraModalProfissional(false)}
      >
        <SelecionarProfissionalNovoAgendamento />
      </ModalLadoDireito>

      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography>Profissional</Typography>

        {profissionalSelecionado === undefined && (
          <ActionButton
            nome="Selecionar Profissional"
            aoClicar={() => setMostraModalProfissional(true)}
            desabilitado={servicoSelecionado === undefined ? true : false}
          />
        )}
        {profissionalSelecionado !== undefined && (
          <CardProfessionalSelected
            avatar={profissionalSelecionado.avatar}
            nome={profissionalSelecionado.nome}
            aoExcluir={() => {
              selecionarProfissional(undefined);
              selecionarDataEHora(undefined);
            }}
          />
        )}
      </Box>
    </>
  );
}
