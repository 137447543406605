import { Box, Button, Container, Typography } from '@mui/material';

export const ErrorPage: React.FC<{ onReloadClick: any }> = ({
  onReloadClick,
}) => {
  return (
    <Container>
      <Box textAlign="center" my={3}>
        <Typography variant="h3" color="error">
          Erro ao carregar a página
        </Typography>
      </Box>
      <Box textAlign="center" my={3}>
        <Typography variant="body1">
          Algo deu errado ao carregar esta página.
        </Typography>
      </Box>
      <Box textAlign="center" my={3}>
        <Button variant="contained" onClick={onReloadClick}>
          Tentar carregar novamente
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorPage;
