import { api } from '../infra/api/http-client';

type PayloadPutUser = {
  name: string;
  phoneNumber: string;
};

type User = {
  id: string;
  name: string;
  email: string;
  avatar: string;
  phoneNumber: string;
};

export const putUser = async (id: string, data: PayloadPutUser) => {
  const { statusCode, errorData } = await api({
    url: `/users/${id}`,
    method: 'PUT',
    data,
  });
  return statusCode === 200 ? { ok: true } : { errorData };
};

export const getUser = async (
  id: string
): Promise<{
  data: User | null;
  isUserNotFound: boolean;
}> => {
  const { data, errorData, statusCode } = await api<User>({
    url: `/users/${id}`,
    method: 'GET',
  });

  let isUserNotFound = false;
  if (statusCode === 404 && errorData?.error === 'UserNotFound') {
    isUserNotFound = true;
  }

  return { data, isUserNotFound };
};
