import { Box, Typography } from '@mui/material';
import { ModalLadoDireito } from '../../../shared/components';
import { useNovoAgendamentoContext } from '../contexts/NewScheduleContext';
import ActionButton from '../share/ActionsButton';
import { SelcionarDataEHorarioNovoAgendamento } from './selectionar-data-e-horario-novo-agendamento/SelecionarDataEHorarioNovoAgendamento';
import CardDateTimeSelected from './card-date-time-selected/CardDateTimeSelected';
import { formatDate } from '../../../shared/utils/date-helpers';

export default function DateTimeNewSchedule() {
  const {
    dataEHoraSelecionada,
    profissionalSelecionado,
    servicoSelecionado,
    setMostraModalDataEHora,
    selecionarDataEHora,
    mostraModalDataEHora,
  } = useNovoAgendamentoContext();

  return (
    <>
      <ModalLadoDireito
        aberto={mostraModalDataEHora}
        tituloDaPagina={'Selecionar Data e Horário'}
        aoClicarEmFechar={() => setMostraModalDataEHora(false)}
      >
        <SelcionarDataEHorarioNovoAgendamento />
      </ModalLadoDireito>

      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography>Data e Horário</Typography>

        {dataEHoraSelecionada === undefined && (
          <ActionButton
            nome="Selecionar Data"
            desabilitado={
              profissionalSelecionado === undefined ||
              servicoSelecionado === undefined
                ? true
                : false
            }
            aoClicar={() => setMostraModalDataEHora(true)}
          />
        )}
        {dataEHoraSelecionada !== undefined && (
          <CardDateTimeSelected
            data={formatDate(dataEHoraSelecionada.data)}
            diaDaSemana={dataEHoraSelecionada.diaDaSemana}
            tempo={dataEHoraSelecionada.tempo}
            aoExcluir={() => selecionarDataEHora(undefined)}
          />
        )}
      </Box>
    </>
  );
}
