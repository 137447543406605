import { Avatar, Box, Paper, Typography, useTheme } from '@mui/material';

export const ProfessionalScheduleDetails = (props: {
  avatar: string;
  name: string;
}) => {
  const theme = useTheme();
  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Typography>Profissional</Typography>
      <Box
        component={Paper}
        display={'flex'}
        alignItems={'center'}
        padding={theme.spacing(2)}
        gap={2}
      >
        <Avatar src={props.avatar} />
        <Typography>{props.name}</Typography>
      </Box>
    </Box>
  );
};
