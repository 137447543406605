import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Appointments } from '../pages/appointments/Appointments';
import { Perfil } from '../pages/perfil';
import { useDrawerContext } from '../shared/contexts';
import { SignInPage } from '../pages/signin/SignInPage';
import { SignInPageProvider } from '../pages/signin/SignInContext';
import { MenuLateral } from '../shared/components';
import { AuthProvider } from '../shared/contexts/auth';
import { CommonProviderPage } from '../shared/contexts/CommonPageContext';
import { SignUpPage } from '../pages/signin/pages/signup/SignUpPage';
import { RouteConfig } from '../shared/config/route';
import { PasswordResetPage } from '../pages/signin/pages/password-reset/PasswordResetPage';
import { NewSchedule } from '../pages/appointment-form/NewSchedule';
import NewScheduleProvider from '../pages/appointment-form/NewScheduleProvider';
import { LayoutBaseDePagina } from '../shared/layout';

export const AppReouters = () => {
  const { setDrawerOption } = useDrawerContext();
  useEffect(() => {
    setDrawerOption([
      {
        icon: 'calendar_month',
        label: 'Agendar',
        path: '/agendamento',
      },
      {
        icon: 'history_note',
        label: 'Agendamentos',
        path: '/agendamentos',
      },
      {
        icon: 'person',
        label: 'Perfil',
        path: '/perfil',
      },
    ]);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/agendamento" />} />
      <Route
        element={
          <CommonProviderPage>
            <MenuLateral />
          </CommonProviderPage>
        }
      >
        <Route
          path="/agendamento"
          element={
            <CommonProviderPage>
              <NewScheduleProvider>
                <LayoutBaseDePagina
                  title="Agendamento"
                  subtitle="Escolha um Horário que se Encaixe Perfeitamente em Sua Agenda"
                >
                  <NewSchedule />
                </LayoutBaseDePagina>
              </NewScheduleProvider>
            </CommonProviderPage>
          }
        />
        <Route
          path="/agendamentos"
          element={
            <CommonProviderPage>
              <AuthProvider>
                <Appointments />
              </AuthProvider>
            </CommonProviderPage>
          }
        />
        <Route
          path="/perfil"
          element={
            <CommonProviderPage>
              <AuthProvider>
                <Perfil />
              </AuthProvider>
            </CommonProviderPage>
          }
        />
      </Route>
      <Route
        path="/signIn"
        element={
          <CommonProviderPage>
            <SignInPageProvider>
              <SignInPage />
            </SignInPageProvider>
          </CommonProviderPage>
        }
      />
      <Route
        path={RouteConfig.signup}
        element={
          <CommonProviderPage>
            <SignInPageProvider>
              <SignUpPage />
            </SignInPageProvider>
          </CommonProviderPage>
        }
      />
      <Route
        path={RouteConfig.passwordReset}
        element={
          <CommonProviderPage>
            <SignInPageProvider>
              <PasswordResetPage />
            </SignInPageProvider>
          </CommonProviderPage>
        }
      />
      <Route path="*" element={<Navigate to={'/'} />} />
    </Routes>
  );
};
