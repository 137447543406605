import { Alert, AlertColor, Snackbar } from '@mui/material';
import { ReactNode, createContext, useContext, useState } from 'react';

export const SnackBarContext = createContext(
  {} as {
    setMessage: (message: string) => void;
    success: () => void;
    error: () => void;
    info: () => void;
    open: () => void;
    close: () => void;
  }
);

export const useSnackBarContext = () => useContext(SnackBarContext);
export const SnackBarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [vertical] = useState<'top' | 'bottom'>('top');
  const [horizontal] = useState<'center' | 'left' | 'right'>('center');
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<AlertColor>('info');

  const open = () => setStatus(true);
  const close = () => setStatus(false);
  const error = () => setType('error');
  const info = () => setType('info');
  const success = () => setType('success');

  return (
    <SnackBarContext.Provider
      value={{ setMessage, error, success, open, close, info }}
    >
      {children}
      <Snackbar
        anchorOrigin={{
          vertical,
          horizontal,
        }}
        autoHideDuration={6000}
        open={status}
        onClose={close}
        key={vertical + horizontal}
      >
        <Alert onClose={close} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};
