import { CompanySegment } from '../api/company.service';
import { appConfig } from '../shared/config';
import { LocalStorage } from '../shared/utils/storage-helpers';

export const localStorage = {
  setSegment(segment: CompanySegment): void {
    LocalStorage.setItem('segment', segment);
  },
  getSegment(): CompanySegment | null {
    return LocalStorage.getValue('segment') as CompanySegment;
  },
  setCompanyId(id: string) {
    LocalStorage.setItem(appConfig.appAccessControlCompaniesLocalKey, id);
  },
  getCompanyId(): string | null {
    return LocalStorage.getValue(
      appConfig.appAccessControlCompaniesLocalKey
    ) as CompanySegment;
  },
};
