import { Avatar, useTheme } from '@mui/material';
import { Box } from '@mui/system';

export const AvatarProfile = (props: { imageUrl: string }) => {
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
      gap={2}
    >
      <Avatar
        sx={{ height: theme.spacing(14), width: theme.spacing(14) }}
        src={props.imageUrl}
      />
    </Box>
  );
};
