import { Button } from '@mui/material';

interface IButtonSignIn {
  loading: boolean;
  onClick: () => void;
}
export const ButtonSignIn: React.FC<IButtonSignIn> = (params) => {
  const { onClick, loading } = params;
  return (
    <>
      {loading ? (
        <Button size="large" variant="contained">
          Entrando...
        </Button>
      ) : (
        <Button size="large" variant="contained" onClick={onClick}>
          Entrar
        </Button>
      )}
    </>
  );
};
