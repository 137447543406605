export default {
  UNKNOWN_ERROR:
    'Ocorreu um erro desconhecido. Por favor, tente novamente mais tarde ou entre em contato com o suporte para obter assistência.',
  CONFLICT_DATE_AND_TIME:
    'Conflitos de data e hora encontrados. Por favor, verifique as informações e tente novamente.',
  USER_NOT_FOUND:
    'Usuário não encontrado. Por favor, realize o login novamente para continuar.',
  PROFESSIONAL_NOT_FOUND:
    'Profissional não encontrado. Por favor, verifique as informações e tente novamente.',
  COMPANY_NOT_FOUND:
    'Estabelecimento não encontrado. Realize o login novamente para continuar, ou entre em contato com o suporte para obter assistência.',
  SERVICE_NOT_FOUND:
    'Serviço não encontrado. Por favor, verifique as informações e tente novamente.',
  EVENT_DATE_BE_PAST_DATE:
    'A data/hora selecionada é uma data/hora passada. Por favor, selecione uma data/hora válida.',

  CREATED_SCHEDULE_SUCCESS:
    'Agendamento criado com sucesso! O seu agendamento foi registrado e está confirmado.',
  CREATE_SCHEDULE_INVALID_FORM:
    'Formulário inválido. Por favor, verifique as informações e tente enviar novamente.',
  USER_CANNOT_BE_PROFESSIONAL:
    'O usuário que está agendando não pode ser também o profissional do agendamento.',
  NETWORK_REQUEST_FAILED:
    'Verique sua conexão com a internet e tente novamente.',
  AUTH_INTERNAL_ERROR: 'Verique sua conexão com a internet e tente novamente.',
  AUTH_USER_NOT_FOUND: 'Não foi encontrada um conta para este e-mail.',
  AUTH_EMAIL_ALREADY_IN_USE: 'Esse e-mail já está em uso',
  AUTH_WRONG_PASSWORD: 'A senha está incorreta',
  COMPANY_VALIDATION_ERROR:
    'Desculpe, ocorreu um erro na validação da empresa selecionada. Por favor, tente novamente para que seja possível criar agendamentos.',
};
