import jwtDecode from 'jwt-decode';
import { LocalStorage } from '../utils/storage-helpers';
import { appConfig } from '../config';

export type TokenType = {
  email: string;
  name: string;
  picture: string;
  user_id: string;
};

export const UserContext = {
  getId: (): string => {
    const token = LocalStorage.getValue(appConfig.keyTokenLocalStorage);
    if (!token) return '';

    const result = jwtDecode<TokenType>(token);
    return result.user_id;
  },
  getAvatar: (): string => {
    const token = LocalStorage.getValue(appConfig.keyTokenLocalStorage);
    if (!token) return '';

    const result = jwtDecode<TokenType>(token);
    return result.picture;
  },
  getEmail: (): string => {
    const token = LocalStorage.getValue(appConfig.keyTokenLocalStorage);
    if (!token) return '';

    const result = jwtDecode<TokenType>(token);
    return result.email;
  },
  getName: (): string => {
    const token = LocalStorage.getValue(appConfig.keyTokenLocalStorage);
    if (!token) return '';

    const result = jwtDecode<TokenType>(token);
    return result.name;
  },
};
