import { Box, Button, Paper, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ModalLadoDireito } from '../../shared/components';
import LinearIndeterminate from '../../shared/components/linear-indeterminate/LinearIndeterminate';
import { useAlertContext } from '../../shared/contexts/AlertContext';
import { UserContext } from '../../shared/contexts/UserAuthContext';
import { LayoutBaseDePagina } from '../../shared/layout';
import { getUser } from '../../api/user';
import { AvatarProfile } from './AvatarProfile';
import { InfoProfile } from './InfoProfile';
import { ProfileEditingForm } from './profile-editing-form';

type Profile = {
  id: string;
  avatar: string;
  fullName: string;
  email: string;
  phone: string;
};

export const Perfil = () => {
  const theme = useTheme();
  const { setConfig: alert } = useAlertContext();
  const [abertoModalEditarDados, setAbertoModalEditarDados] = useState(false);
  const [profile, setProfile] = useState<Profile>({
    avatar: '',
    email: '',
    fullName: '',
    id: '',
    phone: '',
  });
  const [isFindingUser, findingUser] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    findingUser(true);
    const { data: resultUser, isUserNotFound } = await getUser(
      UserContext.getEmail()
    );
    findingUser(false);
    if (isUserNotFound) {
      alert({
        open: true,
        message: 'Não foi possível recuperar os dados do usuário.',
        severity: 'error',
      });
    }
    if (resultUser) {
      setProfile({
        id: resultUser.id,
        email: resultUser.email,
        fullName: resultUser.name,
        phone: resultUser.phoneNumber,
        avatar: resultUser.avatar,
      });
    } else {
      alert({
        open: true,
        message: 'Ocorreu um erro inesperado, tente novamente.',
        severity: 'error',
      });
    }
  };

  const handleCloseModal = () => {
    setAbertoModalEditarDados(false);

    if (reloadData) {
      getUserData();
    }
  };

  const handleEditingDataClick = () => {
    setAbertoModalEditarDados(true);
  };

  const handleOnChangeProfileEditingForm = () => {
    setReloadData(true);
  };

  return (
    <>
      <ModalLadoDireito
        tituloDaPagina="Editar Dados"
        aberto={abertoModalEditarDados}
        aoClicarEmFechar={handleCloseModal}
      >
        <ProfileEditingForm
          id={profile?.id}
          name={profile?.fullName || ''}
          phone={profile?.phone || ''}
          email={profile?.email || ''}
          onChange={handleOnChangeProfileEditingForm}
        />
      </ModalLadoDireito>

      <LayoutBaseDePagina title="Perfil">
        {isFindingUser && <LinearIndeterminate />}

        {!isFindingUser && (
          <Box
            display="flex"
            flex={1}
            maxWidth="sm"
            margin="auto"
            flexDirection="column"
            component={Paper}
            padding={theme.spacing(4)}
          >
            <AvatarProfile imageUrl={profile.avatar} />
            <Box display={'flex'} flexDirection={'column'} gap={5}>
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <InfoProfile
                  icon="person"
                  campo="Nome"
                  descricao={profile.fullName}
                />
                <InfoProfile
                  icon="mail"
                  campo="E-mail"
                  descricao={profile?.email || ''}
                />
                <InfoProfile
                  icon="phone"
                  campo="Celular"
                  descricao={profile?.phone || ''}
                />
              </Box>
              <Button
                size="large"
                variant="contained"
                onClick={handleEditingDataClick}
              >
                Editar Dados
              </Button>
            </Box>
          </Box>
        )}
      </LayoutBaseDePagina>
    </>
  );
};
