import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RouteConfig } from '../../../shared/config/route';

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box display={'flex'} justifyContent={'center'}>
        <Typography fontWeight={'bold'}>Não tem uma conta?</Typography>
      </Box>
      <Button
        size="large"
        variant="outlined"
        onClick={() => navigate(RouteConfig.signup)}
      >
        Inscrever-se no Agenda Fácil
      </Button>
    </Box>
  );
};
