import { ReactNode } from 'react';
import { HttpInterceptor } from '../../infra/api/http-client';
import { AlertProvider } from './AlertContext';
import { ApplicationProvider } from './application';
import { SnackBarProvider } from './snackbar';

export const CommonProviderPage: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <ApplicationProvider>
      <AlertProvider>
        <SnackBarProvider>
          <HttpInterceptor>{children}</HttpInterceptor>
        </SnackBarProvider>
      </AlertProvider>
    </ApplicationProvider>
  );
};
