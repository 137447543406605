import { Box } from '@mui/material';
import apiUrls from '../../../../shared/utils/api-urls';
import LinearIndeterminate from '../../../../shared/components/linear-indeterminate/LinearIndeterminate';
import { getCompanyId } from '../../../../shared/config';
import useRequest from '../../../../shared/hooks/useRequest';
import { Professional } from '../../../../api/professional';
import { useNovoAgendamentoContext } from '../../contexts/NewScheduleContext';
import { ProfessionalCard } from './professional-card/ProfessionalCard';
import { useAnalytics } from '../../../../shared/hooks/useAnalytics';
import { useEffect } from 'react';
import {
  EventAnalytics,
  PageNameAnalytics,
} from '../../../../shared/utils/constants';

export const SelecionarProfissionalNovoAgendamento = () => {
  const {
    selecionarProfissional,
    setMostraModalProfissional,
    servicoSelecionado,
  } = useNovoAgendamentoContext();
  const { logPageView, logError } = useAnalytics();

  const {
    data: professionals,
    isRequesting,
    error,
  } = useRequest<Professional[]>({
    url: `${apiUrls.findServices}/${
      servicoSelecionado?.id
    }/professionals/?companyId=${getCompanyId()}`,
    method: 'GET',
  });

  useEffect(() => {
    logPageView(PageNameAnalytics.pageSelectProfessional);
  }, []);

  useEffect(() => {
    if (error) {
      logError(EventAnalytics.errorLoadProfessionals, error.error ?? '');
    }
  }, [error]);

  const ProfessionalList = () => {
    return (
      <>
        {professionals?.map((item, index) => (
          <ProfessionalCard
            key={index}
            id={item.id}
            nome={item.name}
            avatar={item.avatar}
            avaliacao={''}
            aoClicar={() => {
              selecionarProfissional({
                id: item.id,
                avatar: item.avatar,
                nome: item.name,
              });
              setMostraModalProfissional(false);
            }}
          />
        ))}
      </>
    );
  };

  return (
    <>
      {error && setMostraModalProfissional(false)}

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display={'flex'} flexDirection={'column'} gap={3}>
          {isRequesting && <LinearIndeterminate />}

          {!isRequesting &&
            (professionals && professionals.length > 0 ? (
              <ProfessionalList />
            ) : (
              <>
                Não foi encontrado nenhum profissional.Tente novamente mais
                tarde
              </>
            ))}
        </Box>
      </Box>
    </>
  );
};
