import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export type CancelSchedulingForm = {
  description: string;
};

type CancelSchedulingFormDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleFormSubmit: (data: CancelSchedulingForm) => void;
};

export function CancelSchedulingFormDialog(
  props: CancelSchedulingFormDialogProps
) {
  const maxNoteLength = 50;
  const schemaFormStatus = yup.object().shape({
    note: yup.string().max(maxNoteLength),
  });
  const { register, handleSubmit, formState } = useForm<CancelSchedulingForm>({
    resolver: yupResolver(schemaFormStatus),
  });

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Confirmar Cancelamento de Agendamento</DialogTitle>
      <DialogContent>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <DialogContentText>
            Você está prestes a confirmar o cancelamento do agendamento. Essa
            ação será irreversível e o agendamento será marcado como cancelado.
            Tem certeza de que deseja prosseguir com o cancelamento?
          </DialogContentText>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Box display={'flex'}>
              <Typography flex={1}>Motivo</Typography>
            </Box>
            <TextField
              multiline
              inputProps={{ maxLength: maxNoteLength }}
              rows={3}
              helperText={`limite de ${maxNoteLength} caracteres`}
              {...register('description')}
            />
            <Typography flex={1}>
              {formState.errors.description?.message ?? ''}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Fechar</Button>
        <Button onClick={handleSubmit(props.handleFormSubmit)}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
