import { useEffect, useState } from 'react';
import { ModalLadoDireito } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layout';
import { AppointmentDetails } from './appointment-details/AppointmentDetails';
import { Schedule, cancelScheduling } from '../../api/schdeule';
import { api } from '../../infra/api/http-client';
import apiUrls from '../../shared/utils/api-urls';
import { formatDate } from '../../shared/utils/date-helpers';
import {
  formatHHMMRangeLiteral,
  formatHHMMToLiteral,
} from '../../shared/utils/time-utils';
import { formatCurrency } from '../../shared/utils/currency-helpers';
import { getDescriptionStatusScheduleByState } from '../../shared/utils/data-manipulation';
import { useAnalytics } from '../../shared/hooks/useAnalytics';
import {
  EventAnalytics,
  PageNameAnalytics,
  SchedulingState,
} from '../../shared/utils/constants';
import { MenuTabs } from '../../shared/components/menu-tabs/MenuTabs';
import { AppointmentListLayout } from './appointment-list-layout/AppointmentListLayout';
import AppointmentListItem from './appointment-list-item/AppointmentListItem';
import { useBreakpoint } from '../../shared/hooks/useBreakpoint';
import AppointmentListItemTableHeader from './appointment-list-item/appointment-list-item-table/AppointmentListItemTableHeader';
import { CancelSchedulingForm } from './appointment-details/cancel-scheduling-form-dialog/CancelSchedulingFormDialog';
import alertMessage from '../../shared/utils/messages-helpers';
import { useAlertContext } from '../../shared/contexts/AlertContext';
import CenteredCircularProgress from '../../shared/components/centered-circular-progress/CenteredCircularProgress';
import { UserContext } from '../../shared/contexts/UserAuthContext';

export function Appointments() {
  const [openModal, setOpenModal] = useState(false);
  const [appointmentsOpen, setAppointmentsOpen] = useState<Schedule[]>([]);
  const [appointmentsHistory, setAppointmentsHistory] = useState<Schedule[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [scheduleSelected, setScheduleSelected] = useState<Schedule>();
  const { logPageView, logError, logAppointmentCancelled } = useAnalytics();
  const breakpoints = useBreakpoint();
  const { setConfig: alert } = useAlertContext();

  useEffect(() => {
    getAppointments();
    logPageView(PageNameAnalytics.pageSchedule);
  }, []);

  const getAppointments = async () => {
    setLoading(true);
    const { data, requestSuccess, errorData } = await api<Schedule[]>({
      url: apiUrls.findScheduling(UserContext.getEmail()),
      method: 'GET',
    });
    if (requestSuccess) {
      setAppointmentsOpen(
        data?.filter((item) => item.status === SchedulingState.CREATED) || []
      );
      setAppointmentsHistory(
        data?.filter((item) => item.status !== SchedulingState.CREATED) || []
      );
      if (scheduleSelected) {
        setScheduleSelected(
          data?.filter((item) => item.id === scheduleSelected.id)[0]
        );
      }
    } else {
      logError(EventAnalytics.errorLoadAppointment, errorData?.error ?? '');
    }
    setLoading(false);
  };

  const handleClickItem = (value: Schedule) => {
    setScheduleSelected(value);
    setOpenModal(true);
  };

  const handleCancel = async (data: CancelSchedulingForm) => {
    setLoading(true);
    const { requestSuccess } = await cancelScheduling(
      scheduleSelected?.id,
      data.description
    );
    if (requestSuccess) {
      alert({
        open: true,
        severity: 'success',
        message: 'Agendamento cancelado com êxito',
      });
      setOpenModal(false);
      logAppointmentCancelled();
      getAppointments();
    } else {
      alert({
        open: true,
        severity: 'error',
        message: alertMessage.UNKNOWN_ERROR,
      });
      logError(
        EventAnalytics.errorAppointmentCancelled,
        alertMessage.UNKNOWN_ERROR
      );
    }
    setLoading(false);
  };

  const buildAppointmentDetailsData = (params: Schedule) => {
    return {
      schedulingId: params.id,
      company: params.company.fantasyName,
      date: formatDate(params.eventDate),
      time: formatHHMMRangeLiteral(params.startTime, params.endTime),
      total: formatCurrency(params.service.price),
      status: params.status,
      ratings: params.rating,
      user: {
        name: params.customer.name,
        avatar: params.customer.avatar,
      },
      service: {
        name: params.service.name,
        durationTime: params.service.durationTime,
        price: formatCurrency(params.service.price),
        hasPromotion: params.service.hasPromotion,
        pricePromotion: formatCurrency(params.service.pricePromotion),
      },
      professional: {
        id: params.professional.id,
        avatar: params.professional.user.avatar,
        name: params.professional.user.name,
      },
    };
  };

  const buildAppointmentListItemData = (params: Schedule) => {
    return {
      eventDate: formatDate(params.eventDate),
      fantasyName: params.company.fantasyName,
      startTime: formatHHMMToLiteral(params.startTime),
      professionalName: params.professional.user.name,
      serviceName: params.service.name,
      servicePrice: formatCurrency(params.service.price),
      status: getDescriptionStatusScheduleByState(params.status),
    };
  };

  return (
    <>
      <CenteredCircularProgress show={loading} />

      <ModalLadoDireito
        aberto={openModal}
        tituloDaPagina={'Detalhes do Agendamento'}
        aoClicarEmFechar={() => setOpenModal(false)}
      >
        {scheduleSelected && (
          <AppointmentDetails
            {...buildAppointmentDetailsData(scheduleSelected)}
            onCancel={handleCancel}
          />
        )}
      </ModalLadoDireito>

      <LayoutBaseDePagina title="Agendamentos" padding={0}>
        <MenuTabs
          tabs={[
            {
              label: 'Abertos',
              children: (
                <AppointmentListLayout>
                  <>
                    {breakpoints.desktop && <AppointmentListItemTableHeader />}
                    {appointmentsOpen.map((item, index) => (
                      <AppointmentListItem
                        key={index}
                        {...buildAppointmentListItemData(item)}
                        onClick={() => handleClickItem(item)}
                      />
                    ))}
                  </>
                </AppointmentListLayout>
              ),
            },
            {
              label: 'Histórico',
              children: (
                <AppointmentListLayout>
                  <>
                    {breakpoints.desktop && <AppointmentListItemTableHeader />}
                    {appointmentsHistory.map((item, index) => (
                      <AppointmentListItem
                        key={index}
                        {...buildAppointmentListItemData(item)}
                        status={getDescriptionStatusScheduleByState(
                          item.status
                        )}
                        onClick={() => handleClickItem(item)}
                      />
                    ))}
                  </>
                </AppointmentListLayout>
              ),
            },
          ]}
        />
      </LayoutBaseDePagina>
    </>
  );
}
