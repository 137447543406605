import axios, { AxiosRequestConfig } from 'axios';
import { LocalStorage } from '../../shared/utils/storage-helpers';
import { appConfig } from '../../shared/config';
import { useAlertContext } from '../../shared/contexts/AlertContext';
import { useApplicationContext } from '../../shared/contexts/application';
import apiStatusCode from '../../shared/utils/api-status-codes';

const instance = axios.create({
  baseURL: appConfig.api.base_url + '/api',
});

export const api = async <T = unknown>(options: AxiosRequestConfig) => {
  let data: T | null = null;
  let statusCode: number;
  let errorData: { error: string } | null = null;
  let requestSuccess: boolean;
  try {
    const response = await instance({
      headers: {
        'X-Access-Token': LocalStorage.getValue(appConfig.keyTokenLocalStorage),
      },
      ...options,
    });
    data = response.data;
    statusCode = response.status;
    requestSuccess = true;
  } catch (err: any) {
    statusCode = err?.response?.status || null;
    errorData = err?.response?.data || {};
    requestSuccess = false;
  }
  return { data, statusCode, errorData, requestSuccess };
};

export const HttpInterceptor = (props: { children: any }) => {
  const { redirectToLogin } = useApplicationContext();
  const { setConfig: setAlertConfig } = useAlertContext();

  const resInterceptor = (response: any) => {
    return response;
  };

  const errInterceptor = (error: any) => {
    const statusCode = error?.response?.status;
    if (statusCode === apiStatusCode.FORBIDDEN) {
      LocalStorage.deleteItem(appConfig.keyTokenLocalStorage);
      redirectToLogin();
    }
    if (
      statusCode === apiStatusCode.INTERNAL_SERVER_ERROR ||
      (statusCode === apiStatusCode.NOT_FOUND &&
        error?.response?.data?.error === 'CompanyNotFound')
    ) {
      setAlertConfig({
        open: true,
        severity: 'error',
        message: 'Ocorreu um erro inesperado, tente novamente.',
      });
    }
    if (
      statusCode === apiStatusCode.NOT_FOUND &&
      error?.response?.data?.error === 'UserNotFound'
    ) {
      setAlertConfig({
        open: true,
        severity: 'error',
        message: 'Seu usuário não foi encontrado, tente novamente.',
      });
    }
    return Promise.reject(error);
  };

  instance.interceptors.response.use(resInterceptor, errInterceptor);

  return props.children;
};
