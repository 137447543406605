import { Box, Button, useTheme } from '@mui/material';
import {
  PageNameAnalytics,
  SchedulingState,
} from '../../../shared/utils/constants';
import { UserInfoScheduleDetails } from './user-info-schedule-details/UserInfoScheduleDetails';
import { ProfessionalScheduleDetails } from './professional-schedule-details/ProfessionalScheduleDetails';
import { ServiceScheduleDetails } from './service-schedule-details/ServiceScheduleDetails';
import { useEffect, useState } from 'react';
import {
  CancelSchedulingForm,
  CancelSchedulingFormDialog,
} from './cancel-scheduling-form-dialog/CancelSchedulingFormDialog';
import { formatHHMMToMinutesInLiteral } from '../../../shared/utils/time-utils';
import { useAnalytics } from '../../../shared/hooks/useAnalytics';

export type RatingData = {
  rating: number;
  note: string;
};

export interface AppointmentDetailsProps {
  schedulingId: string;
  company: string;
  date: string;
  time: string;
  total: string;
  status: SchedulingState;
  ratings: {
    star: number | null;
    comment: string;
  } | null;
  user: { name: string; avatar: string };
  service: {
    name: string;
    durationTime: string;
    price: string;
    pricePromotion: string;
    hasPromotion: boolean;
  };
  professional: { id: string; avatar: string; name: string };
  onCancel: (data: CancelSchedulingForm) => void;
}

export function AppointmentDetails(props: AppointmentDetailsProps) {
  const theme = useTheme();
  const [canceledRating] = useState(false);
  const [openCancelSchedullingFormDialog, setOpenCancelSchedullingFormDialog] =
    useState(false);
  const { logPageView } = useAnalytics();

  useEffect(() => {
    logPageView(PageNameAnalytics.pageDetailsSchedule);
  }, []);

  const handleCancelButtonClick = async () => {
    setOpenCancelSchedullingFormDialog(true);
  };

  const handleCancel = (data: CancelSchedulingForm) => {
    setOpenCancelSchedullingFormDialog(false);
    props.onCancel(data);
  };

  return (
    <>
      <CancelSchedulingFormDialog
        open={openCancelSchedullingFormDialog}
        handleFormSubmit={handleCancel}
        handleClose={() => setOpenCancelSchedullingFormDialog(false)}
      />

      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <UserInfoScheduleDetails
          avatar={props.user.avatar}
          username={props.user.name}
          time={props.time}
          date={props.date}
        />
        {props.status === SchedulingState.CREATED && !canceledRating && (
          <>
            <Button
              size={'large'}
              variant={'outlined'}
              sx={{ borderWidth: theme.spacing(0.5) }}
              onClick={handleCancelButtonClick}
            >
              Cancelar
            </Button>
          </>
        )}
        <ProfessionalScheduleDetails
          name={props.professional.name}
          avatar={props.professional.avatar}
        />
        <ServiceScheduleDetails
          name={props.service.name}
          durationTime={formatHHMMToMinutesInLiteral(
            props.service.durationTime
          )}
          price={props.service.price}
          total={props.total}
          hasPromotion={props.service.hasPromotion}
          pricePromotion={props.service.pricePromotion}
        />
      </Box>
    </>
  );
}
