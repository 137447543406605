import { logEvent } from 'firebase/analytics';
import { analytics } from '../config/firebase';
import { EventAnalytics, PageNameAnalytics } from '../utils/constants';
import { getCompanyId } from '../config';
import { UserContext } from '../contexts/UserAuthContext';

const prefix = 'app';

const pageWithoutEmail = [
  PageNameAnalytics.pageSignin,
  PageNameAnalytics.pagePasswordReset,
];

const errorWithoutEmail = [
  EventAnalytics.errorSignin,
  EventAnalytics.errorUserPasswordResetSent,
];

export const useAnalytics = () => {
  const logPageView = (page: string) => {
    const options: any = {};
    const isPageWithoutEmail = pageWithoutEmail.find((item) => item === page);
    if (!isPageWithoutEmail) {
      options.email = UserContext.getEmail();
    }
    logEvent(analytics, `${prefix}_${EventAnalytics.pageView}`, {
      page_name: page,
      company_id: getCompanyId(),
      ...options,
    });
  };

  const logAppoimentCreated = () => {
    logEvent(analytics, `${prefix}_${EventAnalytics.appointmentCreated}`, {
      company_id: getCompanyId(),
      email: UserContext.getEmail(),
    });
  };

  const logUserProfileUpdated = () => {
    logEvent(analytics, `${prefix}_${EventAnalytics.userProfileUpdated}`, {
      company_id: getCompanyId(),
      email: UserContext.getEmail(),
    });
  };

  const logUserPasswordResetSent = () => {
    logEvent(analytics, `${prefix}_${EventAnalytics.userPasswordResetSent}`, {
      company_id: getCompanyId(),
    });
  };

  const logUserRegistrationCreated = () => {
    logEvent(analytics, `${prefix}_${EventAnalytics.userRegistrationCreated}`, {
      company_id: getCompanyId(),
      email: UserContext.getEmail(),
    });
  };

  const logLoginSuccessed = () => {
    logEvent(analytics, `${prefix}_${EventAnalytics.loginSucceeded}`, {
      company_id: getCompanyId(),
      email: UserContext.getEmail(),
    });
  };

  const logAppointmentCancelled = () => {
    logEvent(analytics, `${prefix}_${EventAnalytics.appointmentCancelled}`, {
      company_id: getCompanyId(),
      email: UserContext.getEmail(),
    });
  };

  const logError = (eventName: string, errorMessage: string) => {
    const options: any = {};
    const isErrorWithoutEmail = errorWithoutEmail.find(
      (item) => item === eventName
    );
    if (!isErrorWithoutEmail) {
      options.email = UserContext.getEmail();
    }
    logEvent(analytics, `${prefix}_${eventName}`, {
      company_id: getCompanyId(),
      message: errorMessage,
      ...options,
    });
  };

  return {
    logPageView,
    logAppoimentCreated,
    logError,
    logUserProfileUpdated,
    logUserPasswordResetSent,
    logUserRegistrationCreated,
    logLoginSuccessed,
    logAppointmentCancelled,
  };
};
