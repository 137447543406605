import { Box, Icon, IconButton, Typography } from '@mui/material';
import SelectedItemsLayout from '../../SelectedtemsLayout';

type CardDateTimeSelectedProps = {
  diaDaSemana: string;
  data: string;
  tempo: string;
  aoExcluir: () => void;
};

export default function CardDateTimeSelected(props: CardDateTimeSelectedProps) {
  return (
    <SelectedItemsLayout>
      <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
        <Icon fontSize={'small'}>event</Icon>
        <Typography overflow={'hidden'} textOverflow={'ellipsis'} noWrap>
          {props.diaDaSemana}
        </Typography>
        <Typography overflow={'hidden'} textOverflow={'ellipsis'} noWrap>
          {props.data}
        </Typography>
        <Typography overflow={'hidden'} textOverflow={'ellipsis'} noWrap>
          {props.tempo}
        </Typography>
        <Box
          flex={1}
          gap={2}
          display={'flex'}
          justifyContent={'end'}
          alignItems={'center'}
        >
          <IconButton onClick={props.aoExcluir}>
            <Icon>delete</Icon>
          </IconButton>
        </Box>
      </Box>
    </SelectedItemsLayout>
  );
}
