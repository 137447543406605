import { ReactNode, useEffect, useState } from 'react';
import {
  DataEHoraSelecionadoData,
  NovoAgendamentoContext,
  ProfissionalSelecionadoData,
  ServicoSelecionadoData,
} from './contexts/NewScheduleContext';
import { LocalStorage } from '../../shared/utils/storage-helpers';
import { appConfig } from '../../shared/config';

type NewScheduleProps = {
  children: ReactNode;
};

export default function NewScheduleProvider(props: NewScheduleProps) {
  const APPOINTMENT_JOURNEY_KEY = LocalStorage.getValue(
    appConfig.appAccessControlCompaniesLocalKey
  );

  const [profissional, setProfissional] = useState<
    ProfissionalSelecionadoData | undefined
  >(undefined);
  const [servico, setServico] = useState<ServicoSelecionadoData | undefined>(
    undefined
  );
  const [dataHora, setDataHora] = useState<
    DataEHoraSelecionadoData | undefined
  >(undefined);
  const [
    mostraModalSelecionarProfissional,
    setMostrarModalSelecionarProfissional,
  ] = useState(false);
  const [mostraModalSelecionarServico, setMostrarModalSelecionarServico] =
    useState(false);
  const [mostraModalSelecionarDataEHora, setMostraModalSelecionarDataEHora] =
    useState(false);
  const [scheduleCreated, setScheduleCreated] = useState(false);

  useEffect(() => {
    if (APPOINTMENT_JOURNEY_KEY) {
      const appointmentCached = LocalStorage.getValue(APPOINTMENT_JOURNEY_KEY);
      if (appointmentCached) {
        const { service, professional, datetime } =
          JSON.parse(appointmentCached);
        if (!service) {
          setServico(undefined);
          setProfissional(undefined);
          setDataHora(undefined);
        } else {
          setServico(service);
          if (!professional) {
            setProfissional(undefined);
            setDataHora(undefined);
          } else {
            setProfissional(professional);
            setDataHora(datetime);
          }
        }
      } else {
        setServico(undefined);
        setProfissional(undefined);
        setDataHora(undefined);
      }
    }
  }, []);

  useEffect(() => {
    if (APPOINTMENT_JOURNEY_KEY) {
      if (servico || profissional || dataHora) {
        LocalStorage.setItem(
          APPOINTMENT_JOURNEY_KEY,
          JSON.stringify({
            service: servico,
            professional: profissional,
            datetime: dataHora,
          })
        );
      }
    }
  }, [servico, profissional, dataHora]);

  return (
    <NovoAgendamentoContext.Provider
      value={{
        selecionarProfissional: setProfissional,
        setMostraModalProfissional: setMostrarModalSelecionarProfissional,
        setMostraModalServico: setMostrarModalSelecionarServico,
        setMostraModalDataEHora: setMostraModalSelecionarDataEHora,
        selecionarServico: setServico,
        selecionarDataEHora: setDataHora,
        setScheduleCreated: setScheduleCreated,
        mostraModalProfissional: mostraModalSelecionarProfissional,
        mostraModalServico: mostraModalSelecionarServico,
        mostraModalDataEHora: mostraModalSelecionarDataEHora,
        profissionalSelecionado: profissional,
        servicoSelecionado: servico,
        dataEHoraSelecionada: dataHora,
        scheduleCreated: scheduleCreated,
      }}
    >
      {props.children}
    </NovoAgendamentoContext.Provider>
  );
}
