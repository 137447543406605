import { z } from 'zod';
import { api } from '../infra/api/http-client';

type ValidateCompanyRequest = {
  companyId: string;
};

type ValidateCompanyResponse = {
  valid: boolean;
};

export const validateCompany = (data: ValidateCompanyRequest) => {
  return api<ValidateCompanyResponse>({
    url: '/companies/validate',
    data,
    method: 'POST',
  });
};

const CompanySegmentEnum = z.enum([
  'BARBERS',
  'MANICURE_AND_PEDICURE',
  'SPA',
  'UNISEX_SALON',
] as const);
export type CompanySegment = z.infer<typeof CompanySegmentEnum>;

const CompanyStateEnum = z.enum(['activated', 'disabled'] as const);
export type CompanyState = z.infer<typeof CompanyStateEnum>;

const CompnayResponse = z.object({
  id: z.string(),
  state: CompanyStateEnum,
  segment: CompanySegmentEnum,
  fantasyName: z.string(),
});
export type CompnayResponse = z.infer<typeof CompnayResponse>;

export const getCompanyById = async (id: string): Promise<CompnayResponse> => {
  const result = await api<ValidateCompanyResponse>({
    url: `/companies/${id}`,
    method: 'GET',
  });
  const response = CompnayResponse.parse(result.data);

  return response;
};
