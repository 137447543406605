import { Box, ThemeProvider } from '@mui/material';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DarkTheme } from '../../themes/Dark';
import { LightTheme } from '../../themes/Light';
import { SalonTheme } from '../../themes/Salon';

interface ThemeContextData {
  themeName: 'light' | 'dark' | 'salon';
  toggleTheme: (theme: ThemeType) => void;
}

const ThemeContext = createContext({ themeName: 'salon' } as ThemeContextData);

export const useAppThemeContext = () => {
  return useContext(ThemeContext);
};

export type ThemeType = 'light' | 'dark' | 'salon';

export const AppThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [themeName, setThemeName] = useState<ThemeType>('salon');

  const toggleTheme = useCallback((theme: ThemeType) => {
    setThemeName(theme);
  }, []);

  const theme = useMemo(() => {
    if (themeName === 'light') return LightTheme;
    if (themeName === 'salon') return SalonTheme;

    return DarkTheme;
  }, [themeName]);

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <Box
          width={'100vw'}
          height={'100vh'}
          bgcolor={theme.palette.background.default}
        >
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
