import { Box, Button, TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import 'dayjs/locale/pt-br';
import dayjs, { Dayjs } from 'dayjs';
import { HorarioDisponivel } from './horario-disponivel/HorarioDisponivel';
import { useNovoAgendamentoContext } from '../../contexts/NewScheduleContext';
import { ScheduleAvailableTime } from '../../../../api/schdeule';
import { api } from '../../../../infra/api/http-client';
import apiUrls from '../../../../shared/utils/api-urls';
import { getDescriptionDayWeek } from '../../../../shared/utils/date-helpers';
import LinearIndeterminate from '../../../../shared/components/linear-indeterminate/LinearIndeterminate';
import { getCompanyId } from '../../../../shared/config';
import { useAnalytics } from '../../../../shared/hooks/useAnalytics';
import {
  EventAnalytics,
  PageNameAnalytics,
} from '../../../../shared/utils/constants';

type ResponseAvailableTimeState = {
  availableTimes?: {
    morning: string[];
    evening: string[];
    night: string[];
  } | null;
  isRequesting: boolean;
  error?: any;
};

export const SelcionarDataEHorarioNovoAgendamento = () => {
  dayjs.locale('pt-br');

  const [value, setValue] = useState<Dayjs>(dayjs());
  const [valueSelectDate, setValueSelectDate] = useState<Dayjs | null>(dayjs());
  const [responseAvailableTime, setResponseAvailableTime] =
    useState<ResponseAvailableTimeState | null>(null);
  const [day, setDay] = useState<'today' | 'tomorrow' | 'custom'>('today');
  const { logPageView, logError } = useAnalytics();

  const {
    selecionarDataEHora,
    setMostraModalDataEHora,
    profissionalSelecionado,
    servicoSelecionado,
  } = useNovoAgendamentoContext();

  useEffect(() => {
    findAvailableTime();
  }, [value]);

  useEffect(() => {
    logPageView(PageNameAnalytics.pageSelectDateTime);
  }, []);

  async function findAvailableTime() {
    setResponseAvailableTime({ isRequesting: true });

    api<ScheduleAvailableTime>({
      url: `${
        apiUrls.findSchedulingAvailableTime
      }?companyId=${getCompanyId()}&professionalId=${
        profissionalSelecionado?.id
      }&date=${value.format('YYYY-MM-DD')}&serviceId=${servicoSelecionado?.id}`,
      method: 'GET',
    }).then(({ data, errorData }) => {
      setResponseAvailableTime({
        availableTimes: data,
        isRequesting: false,
        error: errorData,
      });

      if (errorData) {
        logError(EventAnalytics.errorLoadAvailableTime, errorData?.error ?? '');
      }
    });
  }

  function fillNumberLessThanZero(value: string | number) {
    return value.toString().length === 1 ? `0${value}` : value;
  }

  const getTomorrowDate = () => {
    const date = dayjs().toDate();
    const tomorrow = date.setDate(date.getDate() + 1);
    return dayjs(tomorrow);
  };

  const handleAoSelecionarHorario = (horarioSelecionado: string) => {
    selecionarDataEHora({
      data: `${value.year()}-${fillNumberLessThanZero(
        value.month() + 1
      )}-${fillNumberLessThanZero(value.date())}`,
      diaDaSemana: getDescriptionDayWeek(value.day()),
      tempo: horarioSelecionado,
    });

    setMostraModalDataEHora(false);
  };

  const handleOptinDateClick = (type: 'today' | 'tomorrow') => {
    if (type === 'today') {
      setValueSelectDate(dayjs());
      setDay('today');
      setValue(dayjs());
    } else if (type === 'tomorrow') {
      setValueSelectDate(dayjs().add(1, 'day'));
      setDay('tomorrow');
      const tomorrow = getTomorrowDate();
      setValue(tomorrow);
    } else {
      setValueSelectDate(null);
    }
  };

  const handleOnChangeSelectDate = (date: any) => {
    if (date !== null) {
      setDay('custom');
      setValueSelectDate(date);
      setValue(date);
    }
  };

  function isAvailableTimeEmpty() {
    if (!responseAvailableTime?.availableTimes) return true;

    const { morning, evening, night } = responseAvailableTime.availableTimes;

    return morning.length === 0 && evening.length === 0 && night.length === 0
      ? true
      : false;
  }

  const titleDateSelected = () => {
    const today = dayjs().format('DD/MM/YYYY');
    const tomorrow = getTomorrowDate().format('DD/MM/YYYY');

    const dateSelected = value.format('DD/MM/YYYY');

    let title = 'Horários Disponíveis';

    if (dateSelected === today) {
      title = title.concat(' para hoje');
    }

    if (dateSelected === tomorrow) {
      title = title.concat(' para amanhã');
    }

    return title;
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box display={'flex'} justifyContent={'space-evenly'}>
        <Button
          variant={day === 'today' ? 'contained' : 'outlined'}
          onClick={() => handleOptinDateClick('today')}
        >
          Hoje
        </Button>

        <Button
          variant={day === 'tomorrow' ? 'contained' : 'outlined'}
          onClick={() => handleOptinDateClick('tomorrow')}
        >
          Amanhã
        </Button>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Selecione uma data"
            inputFormat="DD/MM/YYYY"
            value={valueSelectDate}
            renderInput={(params) => <TextField {...params} />}
            disablePast
            closeOnSelect
            onChange={handleOnChangeSelectDate}
          />
        </LocalizationProvider>
      </Box>

      {responseAvailableTime?.isRequesting && <LinearIndeterminate />}

      {!responseAvailableTime?.isRequesting &&
        (isAvailableTimeEmpty() ? (
          <>
            Não foi encontrado nenhum horário disponível para esta data.Tente
            novamente mais tarde
          </>
        ) : (
          <Box display={'flex'} gap={1} flexDirection={'column'}>
            <Typography>{titleDateSelected()}</Typography>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <HorarioDisponivel
                aoSelecionarHorario={handleAoSelecionarHorario}
                titulo={'Manhã'}
                horarios={responseAvailableTime?.availableTimes?.morning || []}
              />
              <HorarioDisponivel
                aoSelecionarHorario={handleAoSelecionarHorario}
                titulo={'Tarde'}
                horarios={responseAvailableTime?.availableTimes?.evening || []}
              />
              <HorarioDisponivel
                aoSelecionarHorario={handleAoSelecionarHorario}
                titulo={'Noite'}
                horarios={responseAvailableTime?.availableTimes?.night || []}
              />
            </Box>
          </Box>
        ))}
    </Box>
  );
};
