export default {
  schedulingConflictAtTheSameTime: 'AppointmentConflictTime',
  userNotFound: 'UserNotFound',
  professionalNotFound: 'ProfessionalNotFound',
  companyNotFound: 'CompanyNotFound',
  serviceNotFound: 'ServiceNotFound',
  serviceIsNotPartCompany: 'ServiceIsNotPartCompany',
  eventDateBePastDate: 'EventDateBePastDate',
  schedulingWithEqualUserAndProfessional:
    'AppointmentWithEqualUserAndProfessionalException',
};
