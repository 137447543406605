import { Alert, Snackbar, AlertColor } from '@mui/material';
import { useAlertContext } from '../../contexts/AlertContext';

type AlertSnackBarProps = {
  severity: AlertColor;
  message: string | null;
};

export default function AlertSnackBar({
  severity,
  message,
}: AlertSnackBarProps) {
  const { config, setConfig } = useAlertContext();

  function handleClose() {
    setConfig({ open: false, message: '', severity: 'success' });
  }

  return (
    <>
      <Snackbar
        open={config.open}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
