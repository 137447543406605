import { Avatar, Box, Icon, IconButton, Typography } from '@mui/material';
import SelectedItemsLayout from '../../SelectedtemsLayout';

type CardProfessionalSelectedProps = {
  avatar: string;
  nome: string;
  aoExcluir: () => void;
};

export default function CardProfessionalSelected(
  props: CardProfessionalSelectedProps
) {
  return (
    <SelectedItemsLayout>
      <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
        <Avatar alt="Remy Sharp" src={props.avatar} />
        <Typography overflow={'hidden'} textOverflow={'ellipsis'} noWrap>
          {props.nome}
        </Typography>
        <Box flex={1} display={'flex'} justifyContent={'end'}>
          <IconButton color="default" onClick={props.aoExcluir}>
            <Icon>delete</Icon>
          </IconButton>
        </Box>
      </Box>
    </SelectedItemsLayout>
  );
}
