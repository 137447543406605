import { Box, Icon, IconButton, Typography } from '@mui/material';
import SelectedItemsLayout from '../../SelectedtemsLayout';

interface CardServiceSelectedProps {
  name: string;
  time: string;
  price: string;
  promotionPrice?: string;
  hasPromotion?: boolean;
  onRemove: () => void;
}

export default function CardServiceSelected(props: CardServiceSelectedProps) {
  return (
    <SelectedItemsLayout>
      <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
        <Typography overflow={'hidden'} textOverflow={'ellipsis'} noWrap>
          {props.name}
        </Typography>
        <Box
          flex={1}
          gap={2}
          display={'flex'}
          justifyContent={'end'}
          alignItems={'center'}
        >
          <Typography overflow={'hidden'} textOverflow={'ellipsis'} noWrap>
            {props.time}
          </Typography>
          {props.hasPromotion ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Typography
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                noWrap
                style={{
                  textDecoration: 'line-through',
                }}
                variant="caption"
              >
                {props.price}
              </Typography>
              <Typography overflow={'hidden'} textOverflow={'ellipsis'} noWrap>
                {props.promotionPrice}
              </Typography>
            </Box>
          ) : (
            <Typography overflow={'hidden'} textOverflow={'ellipsis'} noWrap>
              {props.price}
            </Typography>
          )}
          <IconButton onClick={props.onRemove}>
            <Icon>delete</Icon>
          </IconButton>
        </Box>
      </Box>
    </SelectedItemsLayout>
  );
}
