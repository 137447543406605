export const formatHHMMToLiteral = (time: string) => {
  const [hours, minutes] = time.split(':');
  const formattedHours = parseInt(hours) === 1 ? '1h' : `${hours}h`;
  const formattedMinutes = parseInt(minutes) === 1 ? '1min' : `${minutes}min`;
  return `${formattedHours} ${formattedMinutes}`;
};

export const formatHHMMRangeLiteral = (startTime: string, endTime: string) => {
  return `Das ${startTime} às ${endTime}`;
};

export const formatHHMMToMinutesInLiteral = (time: string) => {
  const [hours, minutes] = time.split(':');
  const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);

  if (totalMinutes >= 60) {
    const hourUnit = totalMinutes >= 120 ? 'horas' : 'hora';
    const minuteUnit = totalMinutes % 60 === 0 ? '' : 'minutos';
    const hourValue = Math.floor(totalMinutes / 60);
    const minuteValue = totalMinutes % 60;

    if (minuteValue === 0) {
      return `${hourValue} ${hourUnit}`;
    } else {
      return `${hourValue} ${hourUnit} e ${minuteValue} ${minuteUnit}`;
    }
  } else {
    return `${totalMinutes} ${totalMinutes > 1 ? 'minutos' : 'minuto'}`;
  }
};
