import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from 'firebase/auth';

import { SignInWithEmailPassword } from '../../pages/signin/protocols/signin-email-password.protocol';
import { SignInWithFacebook } from '../../pages/signin/protocols/signin-facebook.protocol';
import { SignInWithGoogle } from '../../pages/signin/protocols/signin-google.protocol';
import { v4 as uuid } from 'uuid';
import { IPasswordReset } from '../../pages/signin/protocols/password-reset.protocol';
import { IRegisterUser } from '../../pages/signin/protocols/register-user.protocol';
import { FirebaseError } from 'firebase/app';
import { InfraExceptionError } from '../helpers/exceptions';
import { faker } from '@faker-js/faker';
import {
  firebaseIdentityClientFactory,
  IdentityClient,
} from '../clients/identity.client';
import { getMessageErrorFirebase } from '../../shared/utils/data-manipulation';

const handleResponseError = (error: any) => {
  if (error instanceof FirebaseError) {
    const code = error.code;
    const errorMessage: string = getMessageErrorFirebase(code);
    throw new InfraExceptionError(errorMessage);
  }
  throw error;
};

class FirebaseSignInWithFacebook implements SignInWithFacebook {
  constructor(private readonly identityClient: IdentityClient) {}

  async exec(): Promise<SignInWithFacebook.Output> {
    try {
      const provider = new FacebookAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      const { user } = await this.identityClient.signInWithRedirect(provider);
      const userEmail = user.email;
      if (userEmail) {
        const providers = await this.identityClient.fetchSignInMethodsForEmail(
          userEmail
        );
        const providerNotExists =
          providers.findIndex((val) => val === 'password') === -1;
        if (providerNotExists) {
          await this.identityClient.linkWithCredential(
            user,
            EmailAuthProvider.credential(userEmail, uuid())
          );
        }
      }
      const token = await user.getIdToken();
      return { token, email: userEmail };
    } catch (error) {
      return handleResponseError(error);
    }
  }
}
class MockSignInWithGoogle implements SignInWithGoogle {
  async exec(): Promise<SignInWithGoogle.Output> {
    return { token: 'any', email: faker.internet.email() };
  }
}
class FirebaseSignInWithGoogle implements SignInWithGoogle {
  constructor(private readonly identityClient: IdentityClient) {}

  async exec(): Promise<SignInWithGoogle.Output> {
    try {
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      const { user } = await this.identityClient.signInWithRedirect(provider);
      const userEmail = user.email;
      if (userEmail) {
        const providers = await this.identityClient.fetchSignInMethodsForEmail(
          userEmail
        );
        const providerNotExists =
          providers.findIndex((val) => val === 'password') === -1;
        if (providerNotExists) {
          await this.identityClient.linkWithCredential(
            user,
            EmailAuthProvider.credential(userEmail, uuid())
          );
        }
      }
      const token = await user.getIdToken();
      return { token, email: userEmail };
    } catch (error) {
      return handleResponseError(error);
    }
  }
}
class FirebaseSignInWithEmailPassword implements SignInWithEmailPassword {
  constructor(private readonly identityClient: IdentityClient) {}

  async exec(
    params: SignInWithEmailPassword.Input
  ): Promise<SignInWithEmailPassword.Output> {
    try {
      const { email, password } = params;
      const credential = await this.identityClient.signInWithEmailAndPassword(
        email,
        password
      );
      const token = await credential.user.getIdToken();
      return { token, email };
    } catch (error) {
      return handleResponseError(error);
    }
  }
}
class FirebasePasswordReset implements IPasswordReset {
  constructor(private readonly identityClient: IdentityClient) {}

  async exec(params: IPasswordReset.Input): Promise<IPasswordReset.Output> {
    try {
      const { email } = params;
      await this.identityClient.sendPasswordResetEmail(email);
      return { status: true };
    } catch (error) {
      return handleResponseError(error);
    }
  }
}

class MockRegisterUser implements IRegisterUser {
  params?: IRegisterUser.Input;
  async exec(params: IRegisterUser.Input): Promise<void> {
    this.params = params;
    Promise.resolve();
  }
}
class FirebaseRegisterUser implements IRegisterUser {
  constructor(private readonly identityClient: IdentityClient) {}

  async exec(params: IRegisterUser.Input): Promise<IRegisterUser.Output> {
    try {
      const { email, password, name } = params;
      const { user } = await this.identityClient.createUserWithEmailAndPassword(
        email,
        password
      );
      await this.identityClient.updateProfile(user, {
        displayName: name,
      });
    } catch (error) {
      return handleResponseError(error);
    }
  }
}

export const firebaseSignInWithGoogleFactory = (): SignInWithGoogle =>
  new FirebaseSignInWithGoogle(firebaseIdentityClientFactory());
export const mockSignInWithGoogleFactory = (): SignInWithGoogle =>
  new MockSignInWithGoogle();
export const firebaseSignInWithFacebookFactory = (): SignInWithFacebook =>
  new FirebaseSignInWithFacebook(firebaseIdentityClientFactory());
export const firebaseSignInWithEmailPasswordFactory =
  (): SignInWithEmailPassword =>
    new FirebaseSignInWithEmailPassword(firebaseIdentityClientFactory());
export const firebasePasswordResetFactory = (): IPasswordReset =>
  new FirebasePasswordReset(firebaseIdentityClientFactory());
export const mockRegisterUserFactory = (): IRegisterUser =>
  new MockRegisterUser();
export const firebaseRegisterUserFactory = (): IRegisterUser =>
  new FirebaseRegisterUser(firebaseIdentityClientFactory());
export class MockSignInWithFacebook implements SignInWithFacebook {
  async exec(): Promise<SignInWithFacebook.Output> {
    return Promise.resolve({ token: 'any', email: 'any' });
  }
}
