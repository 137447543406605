import { Box, Button, useTheme } from '@mui/material';

type ActionButtonProps = {
  nome: string;
  desabilitado?: boolean;
  aoClicar?: () => void;
};

export default function ActionButton(props: ActionButtonProps) {
  const theme = useTheme();

  return (
    <>
      <Box
        component={Button}
        disabled={props.desabilitado}
        fullWidth
        sx={{ border: 'dotted' }}
        padding={theme.spacing(3)}
        borderRadius={theme.spacing(2)}
        onClick={props.aoClicar}
      >
        {props.nome}
      </Box>
    </>
  );
}
