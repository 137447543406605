import { api } from '../infra/api/http-client';
import { UserContext } from '../shared/contexts/UserAuthContext';
import { SchedulingState } from '../shared/utils/constants';

export type ScheduleAvailableTime = {
  morning: string[];
  evening: string[];
  night: string[];
};

export type Schedule = {
  id: string;
  eventDate: string;
  startTime: string;
  endTime: string;
  status: SchedulingState;
  rating: {
    star: number | null;
    comment: string;
  } | null;
  company: {
    id: string;
    cnpj: string;
    contactEmail: string;
    fantasyName: string;
    openingHours: {
      start: string;
      end: string;
    };
  };
  professional: {
    id: string;
    serviceInterval: string;
    user: {
      id: string;
      name: string;
      avatar: string;
      email: string;
      phoneNumber: string;
    };
    companyId: string;
  };
  service: {
    id: string;
    name: string;
    price: number;
    durationTime: string;
    companyId: string;
    hasPromotion: boolean;
    pricePromotion: number;
  };
  customer: {
    id: string;
    name: string;
    avatar: string;
    email: string;
    phoneNumber: string;
  };
};

export type PostSchedulePayload = {
  eventDate: string;
  eventTime: string;
  companyId: string;
  professionalId: string;
  serviceId: string;
  userAuthId: string;
};

export const cancelScheduling = (schedulingId: any, note: string) => {
  return api({
    url: `/scheduling/${schedulingId}/cancel?userAuthEmail=${UserContext.getEmail()}`,
    data: {
      note,
    },
    method: 'PATCH',
  });
};
