import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModalLadoDireito } from '../../../shared/components';
import { useNovoAgendamentoContext } from '../contexts/NewScheduleContext';
import ActionButton from '../share/ActionsButton';
import { SelectServiceNewSchedule } from './select-service-new-schedule/SelectServiceNewSchedule';
import CardServiceSelected from './card-service-selected/CardServiceSelected';

export default function ServiceNewSchedule() {
  const {
    servicoSelecionado,
    setMostraModalServico,
    selecionarServico,
    selecionarDataEHora,
    mostraModalServico,
  } = useNovoAgendamentoContext();

  return (
    <>
      <ModalLadoDireito
        aberto={mostraModalServico}
        tituloDaPagina={'Selecionar Serviço'}
        aoClicarEmFechar={() => setMostraModalServico(false)}
      >
        <SelectServiceNewSchedule />
      </ModalLadoDireito>

      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography>Serviço</Typography>
        {servicoSelecionado === undefined && (
          <ActionButton
            nome="Selecionar Serviço"
            desabilitado={false}
            aoClicar={() => setMostraModalServico(true)}
          />
        )}
        {servicoSelecionado !== undefined && (
          <CardServiceSelected
            name={servicoSelecionado.nome}
            time={servicoSelecionado.tempo}
            price={servicoSelecionado.valor}
            hasPromotion={servicoSelecionado.hasPromotion}
            onRemove={() => {
              selecionarServico(undefined);
              selecionarDataEHora(undefined);
            }}
          />
        )}
      </Box>
    </>
  );
}
