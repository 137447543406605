import { Box, Button, Link, Typography } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InputEmail } from '../../components/InputEmail';
import { IPasswordReset } from '../../protocols/password-reset.protocol';
import { useSignInPageContext } from '../../SignInContext';
import { InfraExceptionError } from '../../../../infra/helpers/exceptions';
import { useSnackBarContext } from '../../../../shared/contexts/snackbar';
import { LayoutBaseAutenticacaoPagina } from '../../../../shared/layout/LayoutBaseAutenticacaoPagina';
import { useAnalytics } from '../../../../shared/hooks/useAnalytics';
import { useEffect } from 'react';
import {
  EventAnalytics,
  PageNameAnalytics,
} from '../../../../shared/utils/constants';

export type PasswordReset = {
  email: string;
};
const passwordRestValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('O e-mail está inválido')
    .required('O e-mail é obrigatório'),
});

export const PasswordResetPage = () => {
  const { passwordReset } = useSignInPageContext();
  const navigate = useNavigate();
  const snackbar = useSnackBarContext();
  const { logPageView, logUserPasswordResetSent, logError } = useAnalytics();

  useEffect(() => {
    logPageView(PageNameAnalytics.pagePasswordReset);
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PasswordReset>({
    resolver: yupResolver(passwordRestValidationSchema),
  });

  const handlePasswordResetSuccess = (data: IPasswordReset.Output) => {
    let message =
      'Ocorreu um erro inesperado, por favor, tente novamente mais tarde.';
    if (data.status) {
      reset({ email: '' });
      message =
        'Foi encaminhado um link no email para redefinição de uma nova senha';
    }
    snackbar.setMessage(message);
    snackbar.info();
    snackbar.open();
    logUserPasswordResetSent();
  };

  const handlePasswordResetError = (error: any) => {
    const errorMessage =
      'Ocorreu um erro inesperado, por favor, tente novamente.';
    snackbar.setMessage(errorMessage);
    if (error instanceof InfraExceptionError) {
      snackbar.setMessage(error.message);
    }
    snackbar.open();
    snackbar.error();
    logError(EventAnalytics.errorUserPasswordResetSent, errorMessage);
  };

  const handleResetPassword: SubmitHandler<PasswordReset> = async (data) => {
    const { email } = data;
    passwordReset
      .exec({ email })
      .then(handlePasswordResetSuccess)
      .catch(handlePasswordResetError);
  };

  return (
    <>
      <LayoutBaseAutenticacaoPagina title="Redefiniação de senha">
        <Box>
          <Typography textAlign={'center'}>
            Insira seu endereço de e-mail que você usou para se registrar. Vamos
            enviar um e-mail um link para você redefinir sua senha
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <InputEmail
            register={register('email')}
            errorMessage={errors.email?.message}
          />
        </Box>
        <Box textAlign={'center'}>
          <Button
            variant={'contained'}
            onClick={handleSubmit(handleResetPassword)}
          >
            Enviar
          </Button>
        </Box>
        <Box textAlign={'center'}>
          <Typography>
            Já tem uma conta?{' '}
            <Link onClick={() => navigate(-1)}>Faça login</Link>.
          </Typography>
        </Box>
      </LayoutBaseAutenticacaoPagina>
    </>
  );
};
