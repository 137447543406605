import { Box, Chip, Icon, Paper, Typography, useTheme } from '@mui/material';

interface AppointmentListItemCardProps {
  data: string;
  horario: string;
  empresa: string;
  profissional: string;
  servico: string;
  status: string;
  aoClicar: () => void;
}

export function AppointmentListItemCard(props: AppointmentListItemCardProps) {
  const theme = useTheme();

  return (
    <Box
      style={{ cursor: 'pointer' }}
      component={Paper}
      display={'flex'}
      flexDirection={'column'}
      gap={1.5}
      padding={theme.spacing(2)}
      onClick={props.aoClicar}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant={'h6'}>{props.empresa}</Typography>
        <Chip label={props.status} />
      </Box>
      <Box display={'flex'} gap={4}>
        <Box display={'flex'} gap={1}>
          <Icon fontSize={'small'}>event</Icon>
          <Typography>{props.data}</Typography>
        </Box>
        <Box display={'flex'} gap={1}>
          <Icon fontSize={'small'}>access_time</Icon>
          <Typography>{props.horario}</Typography>
        </Box>
      </Box>
      <Box display={'flex'} gap={4}>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography fontSize={theme.spacing(1.5)} color={'GrayText'}>
            Profissional
          </Typography>
          <Typography>{props.profissional}</Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography fontSize={theme.spacing(1.5)} color={'GrayText'}>
            Serviço
          </Typography>
          <Typography>{props.servico}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
