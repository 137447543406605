import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { api } from '../../infra/api/http-client';

export default function useRequest<T = unknown>(options: AxiosRequestConfig) {
  const [data, setData] = useState<T | null>(null);
  const [isRequesting, setIsRequest] = useState(true);
  const [error, setError] = useState<{ error: string } | null>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);

  useEffect(() => {
    api<T>(options)
      .then((response) => {
        setData(response.data);
        setError(response.errorData);
        setStatusCode(response.statusCode);
      })
      .finally(() => setIsRequest(false));
  }, []);

  return {
    data,
    error,
    statusCode,
    isRequesting,
  };
}
