import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { appConfig } from '.';

export const firebaseConfig: FirebaseOptions = {
  apiKey: appConfig.firebaseConfigApiKey,
  appId: appConfig.firebaseConfigAppId,
  authDomain: appConfig.firebaseConfigAuthDomain,
  measurementId: appConfig.firebaseConfigMeasurementId,
  messagingSenderId: appConfig.firebaseConfigMessagingSenderId,
  projectId: appConfig.firebaseConfigProjectId,
  storageBucket: appConfig.firebaseConfigStorageBucket,
};

export let firebaseApp: FirebaseApp;
export let analytics: Analytics;

export const firebaseInitializeApp = () => {
  firebaseApp = initializeApp(firebaseConfig);
  analytics = getAnalytics(firebaseApp);
};
