import {
  Box,
  Typography,
  Divider,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { v4 as uuid } from 'uuid';
import { SubmitHandler } from 'react-hook-form';
import { useSignInPageContext } from './SignInContext';
import { ButtonSignIn } from './components/ButtonSignIn';
import { ButtonSignInGoogle } from './components/ButtonSignInGoogle';
import { InputEmail } from './components/InputEmail';
import { InputPassword } from './components/InputPassword';
import { SignInWithEmailPassword } from './protocols/signin-email-password.protocol';
import { useApplicationContext } from '../../shared/contexts/application';
import { useEffect, useState } from 'react';
import { InfraExceptionError } from '../../infra/helpers/exceptions';
import { useSnackBarContext } from '../../shared/contexts/snackbar';
import { Header } from './components/ScreenHeader';
import { Footer } from './components/ScreenFooter';
import { LayoutBaseAutenticacaoPagina } from '../../shared/layout/LayoutBaseAutenticacaoPagina';
import { RouteConfig } from '../../shared/config/route';
import { useDrawerContext } from '../../shared/contexts';
import { EmailAuthProvider, getAuth, getRedirectResult } from 'firebase/auth';
import { firebaseIdentityClientFactory } from '../../infra/clients/identity.client';
import { useAnalytics } from '../../shared/hooks/useAnalytics';
import {
  EventAnalytics,
  PageNameAnalytics,
} from '../../shared/utils/constants';

type LoginValidation = {
  email: string;
  password: string;
};
const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('O e-mail está inválido')
    .required('O e-mail é obrigatório'),
  password: yup
    .string()
    .required('O password é obrigatório')
    .min(6, 'O password deve possuir no mínimo 6 caracteres'),
});

export function SignInPage() {
  const [loadingSignInEmailPassword, setLoadingSignInEmailPassword] =
    useState(false);
  const [loadingSignInGoogle, setLoadingSignInGoogle] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginValidation>({
    resolver: yupResolver(loginValidationSchema),
  });
  const { signInWithEmailPassword, signInWithGoogle } = useSignInPageContext();
  const { updateToken } = useApplicationContext();
  const navigate = useNavigate();
  const snackbar = useSnackBarContext();
  const drawer = useDrawerContext();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const { logPageView, logLoginSuccessed, logError } = useAnalytics();

  const handleSigInSuccess = async (data: SignInWithEmailPassword.Output) => {
    logLoginSuccessed();
    setLoadingSignInGoogle(false);
    setLoadingSignInEmailPassword(false);
    const { token } = data;
    updateToken(token);
    if (drawer.isDrawerOpen) {
      const closeDrawer = () => drawer.toggleDrawerOpen();
      closeDrawer();
    }
    navigateInsideApp();
  };

  function handleSignInError(error: any) {
    setLoadingSignInGoogle(false);
    snackbar.setMessage(
      'Ocorreu um erro inesperado, por favor, tente novamente.'
    );
    if (error instanceof InfraExceptionError) {
      snackbar.setMessage(error.message);
    }
    snackbar.open();
    snackbar.error();
    logError(EventAnalytics.errorSignin, error.message);
  }

  const navigateInsideApp = () => navigate('/', { replace: false });

  const handleLogin: SubmitHandler<LoginValidation> = async (data) => {
    const { email, password } = data;
    setLoadingSignInEmailPassword(true);
    signInWithEmailPassword
      .exec({
        email,
        password,
      })
      .then(handleSigInSuccess)
      .catch(handleSignInError)
      .finally(() => setLoadingSignInEmailPassword(false));
  };

  const handleLoginWithGoogle = () => {
    setLoadingSignInGoogle(true);
    signInWithGoogle
      .exec()
      .then(handleSigInSuccess)
      .catch(handleSignInError)
      .finally(() => setLoadingSignInGoogle(false));
  };

  useEffect(() => {
    logPageView(PageNameAnalytics.pageSignin);
    setOpenBackDrop(true);
    const auth = getAuth();
    getRedirectResult(auth)
      .then(async (result) => {
        try {
          if (result && result.user) {
            const firebaseIdentityClient = firebaseIdentityClientFactory();
            const { email } = result.user;
            if (email) {
              const providers =
                await firebaseIdentityClient.fetchSignInMethodsForEmail(email);
              const providerNotExists =
                providers.findIndex((val) => val === 'password') === -1;
              const { user } = result;
              if (providerNotExists) {
                await firebaseIdentityClient.linkWithCredential(
                  user,
                  EmailAuthProvider.credential(email, uuid())
                );
              }
            }
            const { user } = result;
            const token = await user.getIdToken();
            handleSigInSuccess({
              email,
              token,
            });
          }
          setOpenBackDrop(false);
        } catch (err) {
          setOpenBackDrop(false);
          handleSignInError(err);
        }
      })
      .catch((error) => {
        handleSignInError(error);
      })
      .finally(() => setOpenBackDrop(false));
  }, []);

  return (
    <LayoutBaseAutenticacaoPagina>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Header />
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          {/* <ButtonSignInFacebook
            loading={loadingSignInFacebook}
            onClick={handleLoginWithFacebook}
          /> */}
          <ButtonSignInGoogle
            loading={loadingSignInGoogle}
            onClick={handleLoginWithGoogle}
          />
        </Box>
        <Divider>
          <Typography>ou</Typography>
        </Divider>{' '}
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <InputEmail
            register={register('email')}
            errorMessage={errors.email?.message}
          />
          <InputPassword
            register={register('password')}
            errorMessage={errors.password?.message}
          />
          <Link to={RouteConfig.passwordReset}>Esqueceu sua senha?</Link>
          <Box display={'flex'} justifyContent={'right'}>
            <ButtonSignIn
              loading={loadingSignInEmailPassword}
              onClick={handleSubmit(handleLogin)}
            />
          </Box>
          <br />
          <Divider />
          <Footer />
        </Box>
      </Box>
    </LayoutBaseAutenticacaoPagina>
  );
}
