import { Box, Icon, Typography } from '@mui/material';

export const InfoProfile = (props: {
  icon: string;
  campo: string;
  descricao: string;
}) => {
  return (
    <Box display="flex" gap={2}>
      <Box display="flex" alignItems="center">
        <Icon fontSize="medium" color="primary">
          {props.icon}
        </Icon>
      </Box>
      <Box display="flex" flex={1} flexDirection="column">
        <Typography>{props.campo}</Typography>
        <Typography>{props.descricao}</Typography>
      </Box>
    </Box>
  );
};
