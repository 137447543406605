import { Box, Typography, useTheme } from '@mui/material';

export default function AppointmentListItemTableHeader() {
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      paddingTop={theme.spacing(4)}
      paddingBottom={theme.spacing(4)}
      paddingLeft={theme.spacing(2)}
      paddingRight={theme.spacing(2)}
      gap={2}
    >
      <Typography component={Box} width={theme.spacing(12)}>
        Data
      </Typography>
      <Typography component={Box} width={theme.spacing(16)}>
        Horário
      </Typography>
      <Typography component={Box} width={theme.spacing(20)}>
        Empresa
      </Typography>
      <Typography component={Box} width={theme.spacing(14)}>
        Profissional
      </Typography>
      <Typography component={Box} width={theme.spacing(30)}>
        Serviço
      </Typography>
      <Typography component={Box} width={theme.spacing(10)}>
        Valor
      </Typography>
      <Typography component={Box} width={theme.spacing(10)}>
        Status
      </Typography>
    </Box>
  );
}
