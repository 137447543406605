export class SessionStorage {
  static setItem(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }

  static getValue(key: string) {
    return sessionStorage.getItem(key);
  }
}

export class LocalStorage {
  static setItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  static getValue(key: string) {
    return localStorage.getItem(key);
  }

  static clearAll() {
    return localStorage.clear();
  }

  static deleteItem(key: string) {
    return localStorage.removeItem(key);
  }
}
