import { AlertColor } from '@mui/material';
import { ReactNode, useContext, createContext, useState } from 'react';
import AlertSnackBar from '../components/alert-snackbar/AlertSnackBar';

type AlertProviderProps = {
  children: ReactNode;
};

export type AlertConfig = {
  open: boolean;
  severity: AlertColor | undefined;
  message: string | undefined;
};

type AlertContextData = {
  config: AlertConfig;
  // eslint-disable-next-line no-unused-vars
  setConfig: (val: AlertConfig) => void;
};

const AlertContext = createContext({} as AlertContextData);

export function useAlertContext() {
  return useContext(AlertContext);
}

export function AlertProvider({ children }: AlertProviderProps) {
  const [config, setConfig] = useState<AlertConfig>({
    open: false,
    severity: 'success',
    message: '',
  });

  return (
    <AlertContext.Provider
      value={{
        config,
        setConfig,
      }}
    >
      <>
        <AlertSnackBar
          message={config.message || ''}
          severity={config.severity || 'success'}
        />

        {children}
      </>
    </AlertContext.Provider>
  );
}
