import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  firebasePasswordResetFactory,
  firebaseRegisterUserFactory,
  firebaseSignInWithEmailPasswordFactory,
  firebaseSignInWithFacebookFactory,
  firebaseSignInWithGoogleFactory,
} from '../../infra/firebase';
import { SignInWithEmailPassword } from './protocols/signin-email-password.protocol';
import { SignInWithGoogle } from './protocols/signin-google.protocol';
import { SignInWithFacebook } from './protocols/signin-facebook.protocol';
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from '../../shared/utils/storage-helpers';
import { appConfig } from '../../shared/config';
import { IPasswordReset } from './protocols/password-reset.protocol';
import { IRegisterUser } from './protocols/register-user.protocol';
import { IGetCompanyByUser } from './protocols/get-company-by-user.protocol';
import { apiRestGetCompanyByUserFactory } from '../../infra/rest';
import { RouteConfig } from '../../shared/config/route';

export interface ISignInPageContext {
  signInWithEmailPassword: SignInWithEmailPassword;
  signInWithGoogle: SignInWithGoogle;
  signInWithFacebook: SignInWithFacebook;
  passwordReset: IPasswordReset;
  registerUser: IRegisterUser;
  getCompanyByUser: IGetCompanyByUser;
}
export const SignInPageContext = createContext({} as ISignInPageContext);
export function useSignInPageContext() {
  return useContext(SignInPageContext);
}

export const SignInPageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const [next, setNext] = useState(false);

  useEffect(() => {
    const token = LocalStorage.getValue(appConfig.keyTokenLocalStorage);
    if (token !== null) {
      navigate(RouteConfig.main);
    } else {
      setNext(true);
    }
  }, []);

  return (
    <SignInPageContext.Provider
      value={{
        signInWithEmailPassword: firebaseSignInWithEmailPasswordFactory(),
        signInWithGoogle: firebaseSignInWithGoogleFactory(),
        signInWithFacebook: firebaseSignInWithFacebookFactory(),
        passwordReset: firebasePasswordResetFactory(),
        registerUser: firebaseRegisterUserFactory(),
        getCompanyByUser: apiRestGetCompanyByUserFactory(),
      }}
    >
      {next && children}
    </SignInPageContext.Provider>
  );
};
