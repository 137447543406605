import { Box, useTheme } from '@mui/material';
import { ReactNode } from 'react';

type AppointmentListLayoutProps = {
  children: ReactNode;
};

export function AppointmentListLayout(props: AppointmentListLayoutProps) {
  const { children } = props;

  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      padding={theme.spacing(2)}
      gap={2}
    >
      {children}
    </Box>
  );
}
