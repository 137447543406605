import { Box, Typography, Button } from '@mui/material';

interface HorarioDisponivelProps {
  titulo: string;
  horarios: string[];
  // eslint-disable-next-line no-unused-vars
  aoSelecionarHorario: (horario: string) => void;
}
export const HorarioDisponivel: React.FC<HorarioDisponivelProps> = ({
  titulo,
  horarios,
  aoSelecionarHorario,
}) => {
  return (
    <Box gap={1} display={'flex'} flexDirection={'column'}>
      <Typography>{titulo}</Typography>
      {horarios.length === 0 && (
        <Typography>Nenhum horário disponível no período</Typography>
      )}
      {horarios.length > 0 && (
        <Box
          gap={1}
          display={'flex'}
          flexWrap={'wrap'}
          style={{ width: '100%' }}
        >
          {horarios.map((horario, index) => (
            <Box key={index}>
              <Button
                variant={'outlined'}
                onClick={() => aoSelecionarHorario(horario)}
              >
                {horario}
              </Button>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
